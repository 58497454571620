<template>
  <div class="head-menu">
    <div class="personal_center">
      <img class="avtor" src="../../assets/img/img-top-head.png" alt="" />
      <!-- 个人设置  -->
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <span>{{ information.username }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item class="dropdown-item" icon="el-icon-user" @click="baseInfo"
              >个人中心</el-dropdown-item
            >
            <el-dropdown-item
              class="dropdown-item"
              icon="el-icon-unlock"
              @click="onPassword"
              >管理中心</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <img class="out" @click="logoutFn" src="../../assets/img/icon-top-quit.png" alt="" />
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs, emits } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { l_storage } from '@/utils/storage'
import { delDevice } from '../../api/DeviceManagementapi/DeviceFirmware' //lostorage存储封装
import { logout } from '@/api/login/login'
export default {
  emits: ['setPerMenu'],
  setup(props, context) {
    const router = useRouter()
    const state = reactive({
      information: {},
      tabsActive: 'notice',
      notices: [],
      news: [],
      untreateds: [],
    })

    onMounted(() => {
      methods.getInfo()
    })

    const onManage = (id) => {
      console.log(id)
    }

    const onTab = (tab, event) => {
      console.log(tab, event)
    }

    const logoutFn = () => {
      ElMessageBox.confirm('确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      .then((res) => {
          if (res === 'confirm') {
            logout()
              .then((res) => {
                if (res.code == 0) {
                  router.push({ path: "/" });
                } else {
                  Message.error(res.msg);
                }
              })
              .catch((error) => {
                console.log("失败", error);
              });
          }
        })
        .catch(() => {});
    }

    const baseInfo = () => {
      methods.showMenu()
      router.push({ path: 'essential_information' })
    }
    const onPassword = () => {
      methods.showMenu()
      router.push({ path: 'user_management' })
    }
    const methods = {
      showMenu() {
        context.emit('setPerMenu')
      },
      getInfo() {
        state.information = l_storage.get('information')
      },
    }
    return {
      ...toRefs(state),
      logoutFn,
      baseInfo,
      onPassword,
      ...methods,
      onTab,
      onManage,
    }
  },
}
</script>

<style lang="stylus">
.head-menu {
    text-align: right;
    cursor: pointer;
    margin-right: -24px;
    color #fff
    .el-badge__content.is-fixed.is-dot {
        top: 15px;
    }
}
  .avtor{
    width 38px
    height 38px
    margin-right 8px
  }
  .out{
    margin-right 28px
    margin-left 28px
    width 20px
    height 20px
  }
    .personal_center{
      display flex
      flex-direction row
      align-items center
      justify-content flex-end
      height 58px
    }
.dropdown-item{
  color #fff !important
}
.dropdown-item:hover{
  background #ccc
}
.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
  background: rgba(13, 112, 251, 1)
}
</style>
