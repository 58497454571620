import request from '../../utils/request'

/**********电梯监控***********/
//实时监控列表
export function getMonitorList(data) {
  return request({
    url: '/wy/pc/elevator/monitor/list',
    method: 'post',
    data,
  })
}
//根据用户查询小区列表
export function getVillageList(params) {
  return request({
    url: '/wy/pc/village/list',
    method: 'get',
    params: params,
  })
}
//根据用户查询小区列表
export function getelevatorList(params) {
  return request({
    url: '/wy/pc/elevator/elevator_list',
    method: 'get',
    params: params,
  })
}

//根据deviceId去查询实时状态
export function getLiveDataRent(data) {
  return request({
    url: '/wy/pc/deviceEct/live_data/rent',
    method: 'post',
    data,
  })
}
