/*
 * @Author: dhm 18714820834@163.com
 * @Date: 2022-11-22 10:10:00
 * @LastEditors: dhm 18714820834@163.com
 * @LastEditTime: 2023-03-20 15:00:59
 * @FilePath: \wy-web\src\api\login\login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '../../utils/request'
/*****************登录*********************/
// 登录方法
export function login(data) {
    return request({
        url: '/wy/pc/user/auth/login',
        // url: '/jishiyu/api/login',
        method: 'post',
        data
    })
}

// 获取用户详细信息
export function getInfo(params) {
    return request({
        url: '/wy/pc/user/info/'+params,
        method: 'get',
    })
}

// 退出方法
export function logout() {
    return request({
        url: '/wy/pc/user/auth/logout',
        method: 'post'
    })
}

// 获取验证码
export function getCode(params) {
    return request({
        url: '/wy/pc/user/auth/sms_code',
        method: 'post',
        params
    })
}
// 获取图形验证码
export function getVerificationCode(params) {
    return request({
        url: '/wy/pc/user/verification/code',
        method: 'get'
    })
}
// 校验图形验证码
export function checkVerificationCode(data) {
    return request({
        url: '/wy/pc/user/check/verification/code',
        method: 'post',
        data
    })
}