/*
 * @Author: dhm 18714820834@163.com
 * @Date: 2022-11-22 10:10:00
 * @LastEditors: dhm 18714820834@163.com
 * @LastEditTime: 2023-03-29 17:34:39
 * @FilePath: \wy-web\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import './common/style/main.styl' //引用公用的css
import Mixin from './mixin' // 全局混入
import App from './App.vue'
import router from './router'
import store from './store'
import { l_storage } from '@/utils/storage'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
const app = createApp(App, {
  data() {
    return {
      direction: 'btn',
    }
  },
})

//自定义指令实现按钮权限
app.directive('permission', {
  mounted(el, binding) {
    const { value } = binding
    const roles = localStorage.getItem('btnMenuList')
    let list = JSON.parse(roles)
    let btnList = []
    if(roles){
      list.forEach((items) => {
        if (items.subMenuList && items.subMenuList.length > 0 && items.subMenuList[0] !== null) {
          items.subMenuList.forEach((item) => {
            if (item.subMenuList && item.subMenuList.length > 0) {
              item.subMenuList.forEach((single) => {
                btnList.push(item.name + '-' + single.name)
              })
            } else if (item.link === '') {
              btnList.push(items.name + '-' + item.name)
            }
          })
        }
      })
    }
    
    if (value && value instanceof Array) {
      if (value.length > 0) {
        const permissionRoles = value

        const hasPermission = btnList.some((role) => {
          return permissionRoles.includes(role)
        })

        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      }
    } else {
      throw new Error(`need roles! Like v-permission="['admin','editor']"`)
    }
  },
  updated(el, binding) {},
})

app.use(Viewer)
app.use(store).use(router).use(ElementPlus, { locale }).use(Mixin).mount('#app')

router.beforeEach((to, from, next) => {
  console.log(to.path)
  if (to.path == '/') {
    l_storage.set('information', null)
  }
  let userInfo = l_storage.get('information')
  if (!userInfo && to.path != '/') {
    next({ path: '/' })
  } else {
    //document.title = "吉时语智慧电梯管理平台"
    next()
    l_storage.set('activeMenu', to.path)
  }
})
