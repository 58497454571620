// webscoket 消息状态保存
import axios from 'axios'
const sendWebScoketMsg = (linkId, nodeName, messageStatus, messageDesc) => {
  try {
    axios
      .post(
        'https://test-saas-msg.21.hzjishiyu.com/jsy-msg-trace/msg/trace/process/save_process_record',
        {
          linkId: linkId,
          nodeName: nodeName,
          messageStatus: messageStatus,
          messageSendTime: Date.now(),
          messageDesc: messageDesc || null,
        }
      )
      .then(function(response) {
        console.log(response)
      })
      .catch(function(error) {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}
export default sendWebScoketMsg
