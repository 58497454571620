/***
 *
 * 2021.3.25
 * @本地存储数据封装
 *
 ***/
const storage = window || global;
const ls = storage.localStorage;
const ss = storage.sessionStorage;
// 永久存储
export const  l_storage = {
    get(key) {
        return ls
            ?JSON.parse(ls.getItem(key))
            :null;
    },
    set(key, val = null) {
        key && ls.setItem(key, JSON.stringify(val));
    },
    clear() {
        ls.clear();
    },
    keys() {
        return ls.keys();
    },
    remove(key) {
        this.get(key) && ls.removeItem(key);
    }
};
// 临时存储
export const  s_storage = {
    get(key) {
        return ss
            ?JSON.parse(ss.getItem(key))
            :null;
    },
    set(key, val=null) {
        key && ss.setItem(key, JSON.stringify(val));
    },
    clear() {
        ss.clear();
    },
    keys() {
        return ss.keys()
    },
    remove(key) {
        this.get(key) && ss.removeItem(key);
    }
};
