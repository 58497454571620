/*
 * @Author: dhm 18714820834@163.com
 * @Date: 2022-11-22 10:10:00
 * @LastEditors: dhm 18714820834@163.com
 * @LastEditTime: 2023-02-09 15:50:20
 * @FilePath: \wy-web\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login/login.vue'
import {dynamicRoutes} from './main';  //运维平台路由
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: "/reset",
    component: () => import("@/views/PersonalCenter/BasicInformation/essential_information.vue"),
    hidden: true
  },
  {
    path: "/disclaimers",
    component: () => import("@/views/policyAnnouncement/disclaimers.vue"),
    hidden: true
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
console.log(router);
//添加路由
dynamicRoutes.forEach((item)=>{
    router.addRoute(item)
})
export default router
