<template>
  <el-container class="container">
    <el-aside :width="asideWidth">
      <el-affix :z-index="1200">
        <div class="aside-logo flex" @click="onRefresh">
          <img class="logo-image" src="../../assets/img/logo.png" style="height: 47px" />
          <!-- <div :class="[isCollapse ? 'is-collapse' : '']">
            <p class="home_title">梯无忧</p>
          </div> -->
        </div>
      </el-affix>
      <el-menu
        class="aside-menu"
        router
        unique-opened
        :default-active="activeMenu"
        :collapse="isCollapse"
      >
        <template v-for="(menu, index) in routers" :key="menu.id">
          <el-submenu
            :index="index + ''"
            v-if="(menu.name == '个人中心' || menu.name == '管理中心') && perdonMenuType"
          >
            <template #title>
              <img class="menu_img" :src="imgList[menu.name]" alt="" />
              <span :class="[isCollapse ? 'is-collapse' : '']">{{ menu.name }}</span>
            </template>
            <el-menu-item
              v-for="child in menu.subMenuList"
              :key="child.id"
              :index="child.link"
            >
              <i class="iconfont icon-yuanquan" style="font-size: 13px"></i>

              <template #title>
                {{ child.name }}
              </template>
            </el-menu-item>
          </el-submenu>
        </template>
        <template v-for="(menu, index) in routers" :key="menu.id">
          <el-menu-item
            v-if="menu.subMenuList.length < 2 && menu.link != '' && !perdonMenuType"
            @click="changeMenu(menu)"
            :index="menu.link"
          >
            <img
              class="menu_img"
              v-if="activeMenu == menu.link"
              :src="imgLists[menu.name]"
              alt=""
            />
            <img class="menu_img" v-else :src="imgList[menu.name]" alt="" />

            <span>{{ menu.name }}</span>
          </el-menu-item>
          <template v-else>
            <el-submenu
              :index="index + ''"
              v-if="menu.name != '个人中心' && menu.name != '管理中心' && !perdonMenuType"
            >
              <template #title>
                <img class="menu_img" :src="imgList[menu.name]" alt="" />
                <span :class="[isCollapse ? 'is-collapse' : '']">{{ menu.name }}</span>
              </template>

              <el-menu-item
                v-for="child in menu.subMenuList"
                @click="changeMenu(child)"
                :key="child.id"
                :index="child.link"
              >
                <i class="iconfont icon-yuanquan" style="font-size: 13px"></i>
                <!-- <img class="icon-round" :src="chooseIcon" alt="" /> -->
                <template #title>
                  {{ child.name }}
                </template>
              </el-menu-item>
            </el-submenu>
          </template>
        </template>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header>
        <div class="header_div_shadow"></div>

        <el-row :gutter="20">
          <el-col :span="1">
            <div class="header-collapse" @click="onCollapse">
              <!-- <i :class="[isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold']" /> -->
              <img
                src="../../assets/img/icon-navbar-pack.png"
                alt=""
                style="width: 20px"
              />
            </div>
          </el-col>
          <el-col :span="3">
            <span v-if="perdonMenuType" class="backMenu" @click="onRefresh"
              ><i class="el-icon-arrow-left"></i> 返回菜单页</span
            >
          </el-col>
          <el-col class="header-menu" :span="20">
            <head_menu @setPerMenu="setPerMenu"></head_menu>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <!--<J_welcome></J_welcome>-->
        <router-view />
      </el-main>
    </el-container>
    <div class="right_kuang" v-if="KuangShow" v-drag>
      <i class="el-notification__icon el-icon-warning"></i>
      <div class="el-notification__group is-with-icon">
        <h2 class="el-notification__title">困人提醒</h2>
        <p class="notice_body">{{ tiringInfo.location }}</p>
        <!-- <div class="Button_Style jump" @click="jumplink">查看</div> -->
        <ElButton type="primary" size="small" @click="jumplink">查看</ElButton>
        <ElButton size="small" @click="KuangShow = false">关闭</ElButton>
      </div>
    </div>
    <div class="right_kuang" v-if="CallkuangShow" v-drag>
      <i class="el-notification__icon el-icon-warning"></i>
      <div class="el-notification__group is-with-icon">
        <h2 class="el-notification__title">呼叫等待</h2>
        <p class="notice_body">{{ keyInfo.location }}</p>
        <div style="display: flex;justify-content: space-around;width: 260px;margin-top:30px">
          <ElButton type="primary" size="small" @click="Calljumplink">查看</ElButton>
          <ElButton size="small" @click="CallkuangShow = false">关闭</ElButton>
        </div>
      </div>
    </div>
    <div class="right_kuang" v-if="noticeShow" v-drag>
      <i class="el-notification__icon el-icon-warning"></i>
      <div class="el-notification__group is-with-icon">
        <h2 class="el-notification__title">收到最新的政策公告</h2>
        <p class="notice_body">{{ noticeInfo.location }}</p>
        <div class="Button_Style jump" @click="noticejumplink">查看</div>
      </div>
    </div>
  </el-container>
</template>
<script>
import {
  onBeforeMount,
  reactive,
  toRefs,
  onMounted,
  watch,
  inject,
  getCurrentInstance,
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElButton, ElMessage } from 'element-plus'
import head_menu from '@/views/Home/head_menu'
import J_welcome from '@/views/Home/J_welcome'
import ywrouter from '@/router/ywrouter/index' //运维平台路由
import { getMenuList } from '../../api/home/index'
import { l_storage } from '../../utils/storage' //lostorage存储封装
import home from '../../assets/img/icon-home-leftbar-house-n.png'
import homes from '../../assets/img/icon-home-leftbar-house-s.png'
import homeLight from '../../assets/img/icon-home-leftbar-house-s.png'
import elevator from '../../assets/img/icon-home-leftbar-elevator-n.png'
import elevators from '../../assets/img/icon-home-leftbar-elevator-s.png'
import elevatorLight from '../../assets/img/icon-home-leftbar-elevator-s.png'
import emergency from '../../assets/img/icon-home-leftbar-emergency-n.png'
import management from '../../assets/img/icon-home-leftbar-management-n.png'
import monitoring from '../../assets/img/icon-home-leftbar-monitoring-s.png'
import monitoringLight from '../../assets/img/icon-home-leftbar-monitoring-n.png'
import monitoringLights from '../../assets/img/icon-home-leftbar-monitoring-s.png'
import analysis from '../../assets/img/icon-home-leftbaranalysis-n.png'
import list from '../../assets/img/icon-home-leftbar-list-n.png'
import personal from '../../assets/img/icon-center-personal.png'
import mangermentCenter from '../../assets/img/iocn-center-mangerment.png'
import zhengce from '../../assets/img/zhengce.png'
import zhengces from '../../assets/img/zhengces.png'
import choose from '../../assets/img/icon-navbar-choose-s.png'
import contractManagement from '../../assets/img/icon-contract-management.png'
import contractManagements from '../../assets/img/icon-contract-managements.png'
import publicFeedback from '../../assets/img/icon-public-feedback.png'
import publicFeedbacks from '../../assets/img/icon-public-feedbacks.png'
import repair from '../../assets/img/icon-repair.png'
import sendWebScoketMsg from '@/utils/sendMsg.js'

export default {
  components: {
    head_menu,
    J_welcome,
    ElButton
},
  // 自定义指令
  directives: {
    drag(el, bindings) {
      el.onmousedown = function(e) {
        console.log(e, e.pageX, el.offsetLeft, e.pageY, el.offsetTop)
        var disx = e.pageX - el.offsetLeft
        var disy = e.pageY - el.offsetTop
        document.onmousemove = function(e) {
          el.style.left = e.pageX - disx + 'px'
          el.style.top = e.pageY - disy + 'px'
        }
        document.onmouseup = function() {
          document.onmousemove = document.onmouseup = null
        }
      }
    },
  },
  setup() {
    let store = useStore()
    const router = useRouter()
    const state = reactive({
      websock: null, //websocket
      CallkuangShow: false, //实时困人提醒
      KuangShow: false, //实时呼叫等待
      noticeShow: false, //政策公告等待
      tiringInfo: {},
      keyInfo: {},
      noticeInfo: {},
      isCollapse: false,
      asideWidth: '220px',
      defaultHeight: {
        height: '',
      },
      activeMenu: store.state.menuActiveName,
      routers: [],
      information: l_storage.get('information'),
      perdonMenuType: false,
      homeLight: homeLight,
      chooseIcon: choose,
      imgList: {
        首页: home,
        电梯实时状态: elevator,
        电梯监控: monitoringLight,
        应急处置: emergency,
        电梯管理: management,
        统计分析: analysis,
        告警记录: list,
        个人中心: personal,
        管理中心: mangermentCenter,
        政策公告: zhengce,
        合同管理: contractManagement,
        报修报事: repair,
        公众反馈: publicFeedback,
      },
      imgLists: {
        首页: homes,
        电梯实时状态: elevators,
        电梯监控: monitoringLights,
        合同管理: contractManagements,
        政策公告: zhengces,
        公众反馈: publicFeedbacks,
      },
    })
    //监听小区筛选
    watch(
      () => store.state.menuActiveName,
      (currentValue, oldValue) => {
        state.activeMenu = currentValue
      }
    )
    onBeforeMount(() => {
      console.log(l_storage.get('activeMenu'))
      state.activeMenu = l_storage.get('activeMenu')
      console.log(document.body.clientHeight)
      auditstatus()
      // state.routers = router.options.routes
    })
    onMounted(() => {
      methods.initWebSocket()
      console.log(sessionStorage.getItem('perdonMenuType'))
      if (sessionStorage.getItem('perdonMenuType') == 0) {
        state.perdonMenuType = false
      } else {
        state.perdonMenuType = true
      }
    })
    const reload = inject('reload')
    //获取菜单权限
    const auditstatus = () => {
      var parms = {}
      getMenuList(parms).then((res) => {
        if (res.code == 0) {
          let arr = []
          res.data.forEach((item) => {
            if (item.name == '首页') {
              item.highlightImg = homeLight
              item.img = home
            } else if (item.name == '基础资料') {
              item.highlightImg = elevatorLight
              item.img = elevator
            }
            if (item.subMenuList) {
            } else {
              item.subMenuList = []
              item.subMenuList.push(item)
            }
            arr.push(item)
          })
          var cache = []
          //按钮权限
          var copyData = JSON.stringify(res.data, function(key, value) {
            if (typeof value === 'object' && value !== null) {
              if (cache.indexOf(value) !== -1) {
                // Circular reference found, discard key
                return
              }
              // Store value in our collection
              cache.push(value)
            }
            return value
          })
          localStorage.setItem('btnMenuList', copyData)
          arr.map((r) => {
            r.subMenuList &&
              r.subMenuList.map((j, dex) => {
                if (j.level == '2' && !j.link) {
                  r.subMenuList = []
                  r.subMenuList.push(r)
                }
              })
          })
          state.routers = arr
          // methods.setMenu(arr)
        }
      })
    }
    const onCollapse = () => {
      if (state.isCollapse) {
        state.asideWidth = '220px'
        state.isCollapse = false
      } else {
        state.isCollapse = true
        state.asideWidth = '64px'
      }
    }
    //
    const onRefresh = () => {
      // state.activeMenu='/home'
      sessionStorage.setItem('perdonMenuType', 0)
      state.perdonMenuType = 0
      console.log(sessionStorage.getItem('perdonMenuType'))
      l_storage.set('activeMenu', '/welcome')
      router.push({ path: '/welcome' })
      state.activeMenu = '/welcome'
    }
    const methods = {
      //菜单选择
      // handleSelect(key, keyPath) {
      //   console.log(key, keyPath);
      //   state.activeMenu = keyPath
      //   console.log(activeMenu);
      // },
      //获取菜单
      setMenu(list) {
        console.log(list)
        console.log(ywrouter)

        // console.log(proxy.$router.options.routes);
        let allRouter = ywrouter
        // console.log(allRouter);
        let newItem = []
        let newRouter = list.map((item) => {
          // if(item.path=='/home'){
          //   for(let rr of allRouter){
          //     if(rr.path&&rr.path=='/home'){
          //       return rr;
          //     }
          //   }
          // }

          for (let rr of allRouter) {
            console.log('router-item', rr)
            console.log('menu-item', item.subMenuList)

            if (item.subMenuList && item.subMenuList.length > 0) {
              for (let sonR of item.subMenuList) {
                console.log(sonR.link)
                if (sonR.link == rr.path) {
                  console.log('rrrrrrrrrrrrr', rr)
                  newItem.push(rr)
                }

                // for(let yuanshiItem of rr.children){
                //   if(sonR.path==yuanshiItem.path){
                //     newchildren.push(yuanshiItem);
                //   }
                // }
              }
            }
          }
          return newItem
        })
        // state.routers=newItem
        console.log(newItem)
        console.log(newRouter)
      },
      //个人菜单
      setPerMenu(val) {
        state.perdonMenuType = 1
        sessionStorage.setItem('perdonMenuType', 1)
        console.log(sessionStorage.getItem('perdonMenuType'))
        console.log(state.routers)
      },
      changeMenu(child) {
        store.dispatch('SET_MENUACTIVENAME', child.link)
      },
      //跳转困人通知
      jumplink() {
        if (state.activeMenu === '/tiring_disposal') {
          reload()
          return
        }
        store.dispatch('SET_MENUACTIVENAME', '/tiring_disposal')
        state.KuangShow = false
        router.push({
          path: '/tiring_disposal',
        })
      },
      //跳转一键呼救
      Calljumplink() {
        if (state.activeMenu === '/key_call') {
          reload()
          return
        }
        store.dispatch('SET_MENUACTIVENAME', '/key_call')
        state.CallkuangShow = false
        router.push({
          path: '/key_call',
        })
      },
      //跳转公告
      noticejumplink() {
        if (state.activeMenu === '/policy_announcement') {
          reload()
          return
        }
        store.dispatch('SET_MENUACTIVENAME', '/policy_announcement')
        state.noticeShow = false
        router.push({
          path: '/policy_announcement',
          name: 'policy_announcement',
          params: {
            id: state.noticeInfo.policyNoticeId,
          },
        })
      },
      //获取随机数
      GetRandomNum(Min, Max) {
        var Range = Max - Min
        var Rand = Math.random()
        return Min + Math.round(Rand * Range)
      },
      /*  * 初始化websocket  */
      initWebSocket() {
        let numbers = methods.GetRandomNum(10000, 999999)
        // let numbers2 = methods.GetRandomNum(1000,9999);
        // console.log(store.state.user_info);
        if (!l_storage.get('numbers')) {
          l_storage.set('numbers', numbers)
        }
        let information = l_storage.get('information')
        if (!state.websock) {
          // const wsurl = 'ws://192.168.0.213:6061/jsy-websocket/webSocket/1-wy_pc-global-123456'
          const wsurl =
            process.env.VUE_APP_YW_WS_API +
            '/jsy-websocket/webSocket/' +
            information.id +
            '-' +
            'wy_pc-global-' +
            numbers
          state.websock = new WebSocket(wsurl)
          console.log(state.websock)
          state.websock.onmessage = methods.websocketonmessage
          state.websock.onopen = methods.websocketonopen
          state.websock.onerror = methods.websocketonerror
          state.websock.onclose = methods.websocketclose
        } else {
          console.log('websocket已连接')
        }
      },
      /**
       * 连接建立之后执行send方法发送数据
       */
      websocketonopen(dialogQuery) {
        methods.sendPing() // 发送心跳
      },
      /**
       * 连接失败重新连接
       */
      websocketonerror() {
        clearInterval(state.setIntervalWesocketPush)
        state.websock.close()
        methods.initWebSocket() // 重连
      },
      /**
       * 数据接收
       */
      websocketonmessage(e) {
        if (e.data != 'ping' && JSON.parse(e.data).title) {
          sendWebScoketMsg(JSON.parse(e.data).msgId, '物业全局通知', 1)

          if (JSON.parse(e.data).notificationName == 'lock_notification') {
            state.tiringInfo = JSON.parse(e.data)
            console.log(state.tiringInfo.title)
            state.KuangShow = true
            var KuangShowTime = setTimeout(()=>{
              state.KuangShow = false
              clearTimeout(KuangShowTime)
            },10000)
          } else if (JSON.parse(e.data).notificationName == 'help_notification') {
            state.keyInfo = JSON.parse(e.data)
            state.CallkuangShow = true
            var CallkuangTime = setTimeout(()=>{
              state.CallkuangShow = false
              clearTimeout(CallkuangTime)
            },10000)
          } else if (JSON.parse(e.data).notificationName == 'policy_notice') {
            state.noticeInfo = JSON.parse(e.data)
            state.noticeShow = true
          }
        }
        if (e.data != 'ping' && JSON.parse(e.data).deviceTypeLabel) {
          let log = JSON.parse(e.data)
          console.log(log)
          window.open(log.logUrl, '_self')
        }
      },
      /**
       * 数据发送
       */
      websocketsend(data) {
        if (state.websock !== null && state.websock.readyState === 3) {
          state.websock.close()
          var time = setInterval(
            function() {
              methods.initWebSocket() // 重连
              clearInterval(time)
            },
            Math.ceil(Math.random() * 5) === 0
              ? 1000
              : Math.ceil(Math.random() * 5) * 3000
          ) // 随机3秒内重连
        } else if (state.websock.readyState === 1) {
          state.websock.send(JSON.stringify(data))
        } else if (state.websock.readyState === 0) {
          setTimeout(() => {
            state.websock.send(JSON.stringify(data))
          }, 2000)
        }
      },
      /**
       * 关闭
       */
      websocketclose(e) {
        clearInterval(state.setIntervalWesocketPush)
      },
      /**
       * 发送心跳
       */
      sendPing() {
        state.websock.send('ping')
        state.setIntervalWesocketPush = setInterval(() => {
          state.websock.send('ping')
        }, 30000)
      },
    }
    return {
      ...toRefs(state),
      onCollapse,
      onRefresh,
      ...methods,
    }
  },
}
</script>
<style scoped lang="stylus">

.container{
    background: #F2F5F9;
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
    .el-aside{
        height: 100%;
        transition: all .5s;
        background: rgba(31, 32, 37, 1);
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 4px 0px 10px 0px rgba(31, 61, 89, 0.45);
        z-index: 99;
        .aside-logo{
            height: 59px;
            cursor: pointer;
            background:rgba(31, 32, 37, 1);
            .logo-image {
                margin-top: 12px;
                padding-left: 12px;
            }
        }
        .aside-menu:not(.el-menu--collapse) {
            width: 220px;
        }


        .home_title{
            font-weight: 500;
            color: $base-fontfff;
            line-height: 58px;
            margin-left: 10px;
            font-size:22px;
        }
        .menu_img{
          margin-right: 8px;
        }
        .icon-round{
          width: 14px;
          height: 14px;
        }
        .is-collapse {
            display: none;
        }
    }
    .el-dropdown-menu, .el-menu{
      background-color rgba(31, 32, 37, 1)
    }
    .el-aside::-webkit-scrollbar{
        width: 0px;
    }
    .el-header{
        position relative
        background: white;
        line-height: 60px;
        font-size: 24px;
        .header-collapse{
            cursor: pointer;
        }
        .header-breadcrumb{
            padding-top: 0.9em;
        }
        .header-menu{
            text-align: right;
        }
      .header_div_shadow{
        position absolute
        left:0
        width:100%
        height 10px
        bottom:0
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04);
        z-index: 10;
        transition: 0.3s;
      }
      .backMenu{
        font-size: 14px;
        display: inherit;
        cursor:pointer;
      }
    }

    .el-main{
        padding: 0;
        overflow-x: hidden;
        overflow-y: auto;
        //margin:16px 24px;
        /*background:#fff;*/
        border-radius: 4px;
        /*border: 1px solid #DCE5F0;*/
        position: relative;
    }
    .el-main::-webkit-scrollbar{
        width: 0px;
    }
    .el-icon-s-help{ font-size:12px;}
}
.right_content{
    position:absolute;
    border:1px solid red;
}
.right_kuang{
    width:350px;
    display: flex;
    padding: 14px 26px 14px 13px;
    border-radius: 8px;
    box-sizing: border-box;
    position: fixed;
    background-color:#FFF;
    border-color:#474A6B
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    height:150px;
    right:10px;
    bottom:10px;
    cursor:pointer;
    z-index:9999;
}
.jump{
    margin-top:30px !important;
    margin-left:90px !important;
}
.notice_body{
    margin-top:20px;
    color:#222222 !important;
    font-size:12px;
}
.el-notification{
    border-color:#474A6B !important
}
.el-notification__title{
    text-align:left !important
}
.right_kuang .el-icon-warning {
    color: #E6A23C;
}
.aside-menu .el-menu-item.is-active {
  background: #0D70FB;

}
.aside-menu .el-submenu__title.is-active {
  background: #0D70FB;
}
.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
  background: #0D70FB;
}
>>>.el-submenu.is-active .el-submenu__title{
 color rgba(255,255,255,0.85)!important
}
//>>>.el-submenu__title{
//  background-color rgba(31, 32, 37, 1)
//}
>>>.el-submenu__title{
  color: rgba(255,255,255,0.65)!important
  padding-left 20px !important
}
</style>
