import request from '../../utils/request'
/*****************主页*********************/
// 菜单权限
export function getMenuList(params) {
  return request({
    url: '/wy/pc/user/menu/list',
    method: 'get',
    params: params,
  })
}

// 电梯数量及今日困人、今日一键呼救统计
export function getElevatorData(params) {
  return request({
    url: '/wy/pc/home/elevator/count',
    method: 'get',
    params: params,
  })
}
// 故障告警统计
export function getDeviceErrorCount(params) {
  return request({
    url: '/wy/pc/home/device/error/count',
    method: 'get',
    params: params,
  })
}

// 困人报警统计
export function tiringCount(params) {
  return request({
    url: '/wy/pc/home/lock/event/count',
    method: 'get',
    params: params,
  })
}

// 一键呼救统计
export function keyCount(params) {
  return request({
    url: '/wy/pc/home/call/record/count',
    method: 'get',
    params: params,
  })
}

// 首页数据
export function homeData(params) {
  return request({
    url: '/wy/pc/home/page',
    method: 'get',
    params: params,
  })
}
// 设备数量统计
export function deviceCount(params) {
  return request({
    url: '/ops/pc/home/device/count',
    method: 'get',
    params: params,
  })
}

// 电梯运行数据统计
export function runCount(params) {
  return request({
    url: '/wy/pc/home/ele/run/count',
    method: 'get',
    params: params,
  })
}
// 事件告警统计
export function eventErrorCount(params) {
  return request({
    url: '/wy/pc/home/event/error/count',
    method: 'get',
    params: params,
  })
}
// 电梯状态统计
export function typeCount(params) {
  return request({
    url: '/wy/pc/home/ele/run/count',
    method: 'get',
    params: params,
  })
}
//电梯品牌统计
export function eleCount(params) {
  return request({
    url: '/wy/pc/home/ele/count',
    method: 'get',
    params: params,
  })
}
//困人故障原因分布
export function eleCause(params) {
  return request({
    url: '/wy/pc/home/rescue/fault/type/count',
    method: 'get',
    params: params,
  })
}
