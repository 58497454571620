import { createStore } from 'vuex'
export default createStore({
  state: {
    stus: {},
    user_Info: {},
    eventNotice: {}, //最新事件告警通知
    lockNotice: {}, //最新困人告警通知
    menuActiveName: '',
  },
  mutations: {
    editMenuName: (state, menuActiveName) => {
      state.menuActiveName = menuActiveName
    },
    handleUserInfo: (state, user_Info) => {
      console.log(user_Info)
      state.user_Info = user_Info
      // 把登录的用户的名保存到localStorage中，防止页面刷新，导致vuex重新启动，用户名就成为初始值（初始值为空）的情况
      localStorage.setItem('user_Info', state.user_Info)
    },
    websocketstatus: (state, stus) => {
      state.stus = stus
      // console.log(stus)
    },
    getEventNotice: (state, eventNotice) => {
      state.eventNotice = eventNotice
    },
    getLockNotice: (state, lockNotice) => {
      state.lockNotice = lockNotice
    },
  },
  // mutations的值由actions传入
  actions: {
    // 参数一：自带属性，参数二：新值
    SET_EVENTNOTICE(context, value) {
      // 修改getname的值
      context.commit('getEventNotice', value)
    },
    SET_LOCKOTICE(context, value) {
      // 修改getname的值
      context.commit('getLockNotice', value)
    },
    SET_MENUACTIVENAME(context, value) {
      context.commit('editMenuName', value)
    },
  },
  // getters 只会依赖 state 中的成员去更新
  getters: {
    userInfo: (state) => state.user_Info,
    getProp: (state) => {
      let props = state.user_Info ? state.user_Info.perId.split('|') : []
      props.unshift('8888888888888888')
      return props.map((item) => parseInt(item))
    },
    eventNotice: (state) => state.eventNotice,
    lockNotice: (state) => state.lockNotice,
    menuActiveName: (state) => state.menuActiveName,
  },
  plugins: [
    // persistedstate()
  ],
})
