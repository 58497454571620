import request from '../../utils/request'
/*****************设备固件*********************/
//设备固件列表
export function deviceList(params) {
    return request({
        url: '/ops/pc/device/device_version/list',
        method: 'get',
        params: params
    })
}
//设备固件详情
export function deviceDetail(params) {
    return request({
        url: '/ops/pc/device/device_version/detail',
        method: 'get',
        params: params
    })
}
// 增加设备固件
export function addDevice(data) {
    return request({
        url: '/ops/pc/device/device_version/add',
        method: 'post',
        data
    })
}
// 修改设备固件
export function updateDevice(data) {
    return request({
        url: '/ops/pc/device/device_version/update',
        method: 'post',
        data
    })
}
// 删除设备固件
export function delDevice(params) {
    return request({
        url: '/ops/pc/device/device_version/del/'+params,
        method: 'post',
    })
}
// 当前设备类型下有哪些产品型号
export function deviceVersion(params) {
    return request({
        url: '/ops/pc/device/device_version/get_product_model_by_device_type',
        method: 'get',
        params:params
    })
}
// 版本下拉列表
export function VersionList(params) {
    return request({
        url: '/ops/pc/device/device_version/device/version/list',
        method: 'get',
        params:params
    })
}
// 产品型号列表
export function productList(params) {
    return request({
        url: '/ops/pc/device/product_model/list',
        method: 'get',
        params:params
    })
}
// 产品型号-详情
export function productDetail(params) {
    return request({
        url: '/ops/pc/device/product_model/detail',
        method: 'get',
        params:params
    })
}
// 新增产品型号
export function addProduct(data) {
    return request({
        url: '/ops/pc/device/product_model/add',
        method: 'post',
        data
    })
}
// 修改产品型号
export function editProduct(data) {
    return request({
        url: '/ops/pc/device/product_model/modify',
        method: 'post',
        data
    })
}
// 删除产品型号
export function delProduct(params) {
    return request({
        url: '/ops/pc/device/product_model/del/'+params,
        method: 'post',
    })
}
// 上传文件
export function uploadFile(data) {
    return request({
        url: '/ops/pc/file/upload_obtain_etag_and_url',
        method: 'post',
        data
    })
}