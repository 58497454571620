import Home from '../views/Home/home.vue'
import ywrouter from './ywrouter';  //运维平台路由
export const dynamicRoutes=[
    {
        path: '/Home',
        component: Home,
        name: 'Home',
        iconCls: 'el-icon-message',//图标样式class
        meta: {
            requiresAuth: true,
        },
        children: [
            ...ywrouter
        ]
    },
]
