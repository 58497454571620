<template>
  <div class="login_bg">
    <div class="login_header_title">梯无忧</div>
    <div class="login_content">
      <div class="img_login_pic">
        <img src="../../assets/img/img_login_pic.png" alt="" />
      </div>
      <div class="login_box">
        <el-form class="Log_content">
          <div class="login_Welcome">
            <p>梯无忧管理平台</p>
          </div>
          <!--账号密码登录-->
          <el-form
            class="Account_login"
            status-icon
            :rules="rules"
            :class="{ on: loginWay }"
            :model="ruleForm"
            ref="ruleFormsss"
          >
            <!--              <p class="login_smalltitle">用户名</p>-->
            <el-form-item prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入用户名"></el-input>
              <el-input style="position: fixed; bottom: -999px" tabindex="-1"></el-input>
            </el-form-item>
            <!--              <p class="login_smalltitle">密码</p>-->
            <el-form-item prop="pass" style="">
              <el-input
                style="position: fixed; bottom: -999px"
                type="password"
                tabindex="-1"
              ></el-input>
              <el-input
                type="password"
                v-model="ruleForm.pass"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            
            <div style="position: relative" class="verification">
              <!--                <p class="login_smalltitle">验证码</p>-->
              <el-form-item class="send_code_item" prop="verificationCode">
                <el-input
                  v-model="ruleForm.verificationCode"
                  placeholder="请输入图形验证码"
                ></el-input>
              </el-form-item>
              <el-button
                class="Send_Code f_l"
                style="width:40%"
                @click="getVerificationCodeFn"
                ><img class="" sytle="padding-right:4px;" :src="codeImage" /><i style="padding-left:12px;display: inline-block;position: relative;top: -10px;">换一个</i>
              </el-button>
            </div>
            <!-- <div style="display: flex;justify-content: space-between;">
              <el-form-item prop="pass" style="margin-bottom: 0px">
                <el-input
                  style="position: fixed; bottom: -999px"
                  tabindex="-1"
                ></el-input>
                <el-input
                  v-model="ruleForm.pass"
                  placeholder="请输入图形验证码"
                ></el-input>
              </el-form-item>
              <div class="v_code flex flex-s">
                <img :src="codeImage" />
                <div
                  style="color: #fff; margin-left: 10px"
                  @click="getVerificationCodeFn"
                >
                  换一张
                </div>
              </div>
            </div> -->

            <el-checkbox v-model="checked" class="remember">记住密码</el-checkbox>
          </el-form>
          <!--手机验证码登录-->
          <el-form
            class="Mobile_login"
            :class="{ on: !loginWay }"
            status-icon
            :rules="rules_two"
            :model="ruleForms"
            ref="ruleFormtwo"
          >
            <!--              <p class="login_smalltitle">手机号</p>-->
            <el-form-item prop="phone">
              <el-input v-model="ruleForms.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <div style="position: relative" class="verification">
              <!--                <p class="login_smalltitle">验证码</p>-->
              <el-form-item class="send_code_item" prop="Verification">
                <el-input
                  v-model="ruleForms.Verification"
                  placeholder="请输入验证码"
                ></el-input>
              </el-form-item>
              <el-button
                class="Send_Code f_l"
                @click="sendcode"
                :disabled="disabled"
                v-if="disabled == false"
                >发送验证码
              </el-button>
              <el-button
                class="Send_Code f_l"
                @click="sendcode"
                :disabled="disabled"
                v-if="disabled == true"
                >{{ btntxt }}
              </el-button>
            </div>
          </el-form>
          <span class="Login_mode" v-show="loginWay" @click="loginWays"
            >短信快捷登陆</span
          >
          <span class="Login_mode" v-show="!loginWay" @click="loginWays"
            >账号密码登录</span
          >
          <input type="button" value="登录" class="loin_submit" @click="submitForm" />

          <div class="app_code">
            <el-popover placement="bottom-start" :width="220" trigger="hover">
              <div class="qrcode_div">
                <!-- <div class="code_flex">
                  <img src="@/assets/img/ios_qrcode.png" alt="ios_qrcode" />
                  <div>IOS下载</div>
                </div> -->
                <div class="code_flex">
                  <img src="@/assets/img/android_qrcode.png" alt="android_qrcode" />
                  <div>Android下载</div>
                </div>
              </div>
              <template #reference>
                <a type="text">下载梯无忧APP</a>
              </template>
            </el-popover>
          </div>
        </el-form>
      </div>
    </div>
    <!--        <div class="video_mask" style="display: block">-->
    <!--            <p>当前版本号为：<span id="VersionNumber">v2.10.0.0312</span></p>-->
    <!--        </div>-->
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  defineComponentprovide,
  onMounted,
  ref,
  unref,
  computed,
} from 'vue'
import { useRouter } from 'vue-router' //导入路由
import md5 from 'js-md5'
import { ElMessage, ElMessageBox  } from 'element-plus'
import { login, getCode, getVerificationCode, checkVerificationCode } from '../../api/login/login'
import { useStore } from 'vuex'
import { l_storage } from '@/utils/storage' //lostorage存储封装
export default {
  setup(props, { refs, root }) {
    let store = useStore()
    const router = useRouter()
    console.log(store, store.state.name)
    // 定义变量
    const state = reactive({
      codeImage: "",
      loginWay: true, // 切换账号/手机密码登录
      checked: false, //记住密码
      ruleForm: {
        pass: '', //密码
        name: '', //用户名
        verificationCode: '', //验证码
      },
      ruleForms: {
        phone: '', //手机号
        Verification: '', // 验证码
      },
      disabled: false,
      time: 0,
      btntxt: '重新发送',
      ruleFormsss: ref(null),
      ruleFormtwo: ref(null),
    })
    var checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入用户名'))
      }

      if (/^[A-Za-z0-9]{1,20}$/.test(value)) {
        return callback()
      } else {
        return callback(new Error('只能输入1-20个字母、数字、下划线,不允许中文'))
      }
    }
    var checkPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入密码'))
      }
      return callback()
      // if (/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,16}$/.test(value)) {
      //   return callback()
      // } else {
      //   return callback(new Error('密码必须不少于8位，包含大小写、字母、字符至少三种'))
      // }
    }
    const rules = {
      //账号密码验证
      pass: [{ validator: checkPass, trigger: 'change' }],
      name: [{ validator: checkName, trigger: 'change' }],
      verificationCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
    }
    const rules_two = {
      //手机登录验证
      phone: [
        { required: true, message: '请输入手机号', trigger: 'change' },
        { pattern: /1\d{10}/, message: '请输入合法手机号', trigger: 'change' },
      ],
      Verification: [{ required: true, message: '请输入验证码', trigger: 'change' }],
    }
    // 清空表单
    // const resetForm = formName => {
    //   refs[formName].resetFields();
    // };
    //初始
    onMounted(() => {
      methods.getCookie()
      methods.getVerificationCodeFn();
      let meth = methods
      document.onkeydown = function(e) {
        if (window.event == undefined) {
          var key = e.keyCode
        } else {
          var key = window.event.keyCode
        }
        //enter的ASCII码是13
        if (key == 13) {
          submitForm()
        }
      }
    })
    let methods = {
      getVerificationCodeFn() {
        getVerificationCode({}).then((res) => {
          state.codeImage = "data:image/jpg;base64," + res.data;
        });
      },
      loginWays() {
        // 切换账号、手机密码登录
        state.loginWay = !state.loginWay
      },
      //发送验证码
      sendcode() {
        //验证手机号
        const reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/
        if (state.ruleForms.phone == '') {
          ElMessage.warning('手机号不能为空')
          return
        }
        if (!reg.test(state.ruleForms.phone)) {
          ElMessage.warning('请输入正确的手机号')
          return
        } else {
          //清空用户信息
          l_storage.set('information', null)
          //请求接口
          let params = {
            phone: state.ruleForms.phone,
          }
          getCode(params)
            .then((res) => {
              ElMessage.success(res.msg)
            })
            .catch((error) => {
              ElMessage.error(res.msg)
            })
          state.time = 60
          state.disabled = true
          methods.timer()
        }
      },
      //60S倒计时
      timer() {
        if (state.time > 0) {
          state.time--
          state.btntxt = state.time + 's'
          setTimeout(methods.timer, 1000)
        } else {
          state.time = 0
          state.btntxt = '重新获取验证码'
          state.disabled = false
        }
      },
      //设置cookie
      setCookie(c_name, c_pwd, exdays) {
        var exdate = new Date() //获取时间
        exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) //保存的天数
        //字符串拼接cookie
        window.document.cookie =
          'userName' + '=' + c_name + ';path=/;expires=' + exdate.toGMTString()
        window.document.cookie =
          'userPwd' + '=' + c_pwd + ';path=/;expires=' + exdate.toGMTString()
      },
      //读取cookie
      getCookie: function() {
        if (document.cookie.length > 0) {
          state.checked = true
          var arr = document.cookie.split('; ') //这里显示的格式需要切割一下自己可输出看下
          for (var i = 0; i < arr.length; i++) {
            var arr2 = arr[i].split('=') //再次切割
            //判断查找相对应的值
            if (arr2[0] == 'userName') {
              state.ruleForm.name = arr2[1] //保存到保存数据的地方
            } else if (arr2[0] == 'userPwd') {
              state.ruleForm.pass = arr2[1]
            }
          }
        }
      },
      //清除cookie
      clearCookie: function() {
        methods.setCookie('', '', -1) //修改2值都为空，天数为负1天就好了
      },
    }
    //登录
    const submitForm = async () => {
      let params = {}
      if (state.loginWay == true) {
        const form = unref(state.ruleFormsss)
        // form.validate()
        // console.log(form.validate());

        if (!form) return
        try {
          form
            .validate()
            .then((res) => {
              checkVerificationCode({
                verificationCode: state.ruleForm.verificationCode,
              }).then((res) => {
                if (res.code == 0 || location.hostname.indexOf('192.168')!=-1 || location.hostname.indexOf('localhost')!=-1) {
                  params = {
                    clientId: 1003,
                    loginType: 1,
                    username: state.ruleForm.name,
                    password: md5(state.ruleForm.pass),
                    // password: state.ruleForm.pass,
                    // "systemId":5,
                  }
                  login(params)
                    .then((res) => {
                      console.log(res)
                      if (res.code == 0) {
                        let lastUpdateTime = res.data.lastUpdateTime || 0;
                        let nowTime = new Date().getTime();
                        let isTimeFlag = nowTime - lastUpdateTime;
                        let timeFlag = 30 * 24 * 60 * 60 * 1000;

                        // 用户进入登录页，输入用户名、密码、验证码，点击登录，发送登录请求，登录成功之后，
                        // 将 token 保存在 sessionStorage，然后跳转到首页 /home ，进入路由拦截的逻辑。
                        //登录信息存入vuex
                        l_storage.set('information', res.data) //同步存储token至localStorage
                        store.commit('handleUserInfo', res.data)
                        sessionStorage.setItem('perdonMenuType', 0)
                        console.log(store.state.userInfo)
                        //判断复选框是否被勾选 勾选则调用配置cookie方法
                        if (state.checked == true) {
                          //传入账号名，密码，和保存天数3个参数
                          methods.setCookie(state.ruleForm.name, state.ruleForm.pass, 7)
                        } else {
                          // ElMessage.success(res.msg)
                          //清空Cookie
                          methods.clearCookie()
                        }
                        const finalCheckPwd = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,16}$/;
 
                        // 校验密码
                        const checkPwd = finalCheckPwd.test(state.ruleForm.pass);
                        if (!checkPwd) {
                          ElMessageBox.alert(
                            "密码必须不少于8位，包含大小写、字母、字符、数字至少三种！",
                            "提示",
                            {
                              confirmButtonText: "确定",
                              // cancelButtonText: "取消",
                              showCancelButton: false,
                              showClose: false,
                              type: "warning",
                            }
                          )
                            .then(() => {
                              router.push("/reset");
                              l_storage.set("reset", true);
                            })
                            .catch(() => {});
                          console.log("需要修改密码");
                        } else {
                          router.push("/welcome");
                      }
                        // if (!lastUpdateTime || isTimeFlag - timeFlag > 0) {
                        //   ElMessageBox.alert(
                        //       "30天内未修改密码，请先修改密码再操作系统！",
                        //       "提示",
                        //       {
                        //         confirmButtonText: "确定",
                        //         // cancelButtonText: "取消",
                        //         showCancelButton: false,
                        //         showClose: false,
                        //         type: "warning",
                        //       }
                        //     )
                        //       .then(() => {
                        //         router.push("/reset");
                        //         l_storage.set("reset", true);
                        //       })
                        //       .catch(() => {});
                        //     console.log("需要修改密码");
                        //   } else {
                        //     router.push("/welcome");
                        //   }
                      } else {
                        console.log(res)
                        ElMessage.error(res.msg)
                      }
                    })
                    .catch((error) => {
                      console.log('失败', error)
                    })
                }else{
                  console.log(res)
                  ElMessage.error(res.data.msg);
                  methods.getVerificationCodeFn();
                }
                
              })
             
            })
            .catch((err) => {
              ElMessage.error('请输入正确的用户名和密码/验证码')
            })
        } catch (error) {}
      } else {
        const forms = unref(state.ruleFormtwo)
        console.log(forms, !forms)
        if (!forms) return
        try {
          forms
            .validate()
            .then((res) => {
              params = {
                clientId: 1001,
                loginType: 2,
                phone: state.ruleForms.phone,
                verifyCode: state.ruleForms.Verification,
                // "systemId":2
              }
              login(params)
                .then((res) => {
                  console.log(res)
                  if (res.code == 0) {
                    // 用户进入登录页，输入用户名、密码、验证码，点击登录，发送登录请求，登录成功之后，
                    // 将 token 保存在 sessionStorage，然后跳转到首页 /home ，进入路由拦截的逻辑。
                    //登录信息存入vuex
                    l_storage.set('information', res.data) //同步存储token至localStorage
                    store.commit('handleUserInfo', res.data)
                    sessionStorage.setItem('perdonMenuType', 0)
                    //判断复选框是否被勾选 勾选则调用配置cookie方法
                    if (state.checked == true) {
                      //传入账号名，密码，和保存天数3个参数
                      methods.setCookie(state.ruleForm.name, state.ruleForm.pass, 7)
                    } else {
                      ElMessage.success(res.msg)
                      //清空Cookie
                      methods.clearCookie()
                    }
                    router.push({
                      name: 'welcome',
                    })
                  } else {
                    ElMessage.error(res.msg)
                  }
                })
                .catch((error) => {
                  console.log('失败', error)
                })
            })
            .catch((err) => {
              ElMessage.error('请输入正确的手机号和验证码')
            })
        } catch (error) {}
      }
      console.log(params)
      //登录
    }

    return {
      ...toRefs(state),
      ...methods,
      rules,
      rules_two,
      submitForm,
    }
  },
}
</script>
<style scoped lang="stylus">
.Mobile_login,.Account_login,.Login_phone,{ display:none}
.on{ display:block !important;}
.login_smalltitle{
    height: 40px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.45);
    line-height: 40px;
}
.login_bg{
    height: 100%;
    margin: 0 auto;
    width: 100%;
    background-image:  url("../../assets/img/login_bg.png")
    position: fixed;
    background-size: 100% 100%;}
  .img_login_pic{

    margin: auto;

  }
.login_header_title{
    font-size: 28px;
    color: #0056D0;
    letter-spacing: 2px;
    font-width: 500;
    text-align: left;
    margin-left:18%;
    height: 8vw;
    line-height: 8vw;
    display: block;}
.login_Welcome{
  margin-bottom 47px
}
.login_Welcome p{
    font-size: 24px;
    font-weight: 600;
    text-align:center;
    color:$base-blackfont85;
    letter-spacing: 3px;
}
.login_Welcome span{
    width: 5px;
    height: 22px;
    background: #4898ff;
    margin-right:10px;
    display: block;}
.login_box{

  margin: auto;

  display flex
  align-items center
}
.Log_content{
    background-color #fff
    background-size: 100% 100%;
  width: 464px;
  height 476px
  padding 51px 48px
  box-sizing border-box
  position relative
  box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.06);
}
.Log_content:before{
  content:url('../../assets/img/img-login- circle.png')
  position:absolute
  top -30px
  right -43px
  z-index: -1;

}
.el-form-item{
    width: 100%;
    margin: 0 auto;
    margin-bottom: 32px;
    height: 36px;
}
.el-form-item >>>  .el-form-item__content{
    line-height: 40px;
    position: relative;
    font-size: 14px;
    margin-left: 0px!important}
.el-form-item >>> .el-input__inner{
    border-radius:0px;
    border:none;
    padding-left 24px
    border: 1px solid #E9EDF2;
    height: 40px;
    line-height: 40px;
    //padding: 0px;
    text-align:left
    //color: rgba(0, 0, 0, 0.25);
    background :none;}
.el-form >>> .el-form-item.is-error .el-input__inner{
    border-color: #F56C6C !important;
}
.el-form-item >>> .el-checkbox__inner{
    background-color:rgba(255, 255, 255, 0.65)
}
.el-form >>>  .el-input{width:100%}
.loin_submit{
    margin-top:20px;
    color: #fff;
    font-size: 16px;
    border: 0 none;
    width: 100%;
    height: 36px;
    background:#0D70FB;
    opacity: 1;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;}
.Logon_vertical{
    width:2px;
    height:20px;
    color: #297AFF;
    float: left;
    margin: 0 .06rem;
    line-height: 36px;}
.Login_mode{
    height: 50px;
    font-size: 14px;
    color: #1890FF;
    margin: 0 auto;
    text-align: right;
    margin-top: 16px;
    line-height: 50px;
    float: right;
    cursor: pointer;}
.Send_Code{
    font-size: 14px;
    padding: 0px;
    cursor: pointer;
    width: 22%;
    color: #1890ff;
    position: absolute;
    right: 0px;
    bottom: -4px;
    background: none;
    border: none;
}
.remember{margin:33px 0 0 0; float: left;
    color: rgba(255, 255, 255, 0.65);}
.remember >>> .el-checkbox__label{font-size:14px;color:rgba(0, 0, 0, 0.65)}
.btnBg{
    background:#4898FF;}
.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{
    background:none
    color:#4898FF
    border-color: #4898FF}
.Login_pass{
    margin-top:-20px;
}
.verification >>> .el-input__suffix{
    margin-right:80px;
}
.video_mask{
    width:100%;
    height:5%;
    position:absolute;
    bottom:0;
    opacity: .8;
    z-index:90;
    text-align: center;}
.video_mask p{
    cursor: pointer;
    font-size: 14px;
    color: #D5D7DF;
    padding-top: 0.5%;}
.send_code_item{
  margin-bottom 0
  width 100%
}
.login_content{
  display: flex
  justify-content: space-around
  width 80%
  margin 5% 0 0 10%

}
.app_code{
  margin-top: 16px
  font-size: 14px;
  font-family: PingFangSC;
  line-height: 20px;

  a{
    text-decoration: underline
    color: #0D70FB;
    cursor: pointer
  }
}
 .qrcode_div{
    display: flex
    flex-wrap:nowrap
    justify-content: space-around
    font-size: 14px;
    font-family: PingFangSC;
    color: #000000;
    .code_flex{
      display: flex
      flex-direction:column
      align-items: center
    }
    img{
      width:160px
      height:160px
    }

  }
</style>
