<template>
  <router-view v-if="showPage"/>
</template>

<script>
import { reactive ,toRefs ,provide,ref ,readonly,nextTick} from "vue";
export default {
  name: "J_welcome",
  setup() {
    const showPage = ref(true)
    const onRefresh = () => {
      showPage.value = false
      nextTick(() => {
        showPage.value = true
      })
    }
    provide('reload', onRefresh)
    return {
      showPage
    }
  }
}
</script>
<style lang="stylus">



</style>
