<template>
  <div style="width: 100%" class="container">
    <!--<div class="Welcomes_p">吉时语智慧电梯管理平台</div>-->
    <!--当前城市数据显示-->
    <div class="container_top_city">
      <el-row>
        <el-col :span="22">
          <span class="current_city">当前区域：</span>
          <el-popover placement="bottom-start" :width="570" trigger="click">
            <template #reference>
              <div class="item_title">
                <span class="city_select">{{ showCommunityList
                }}<i class="el-icon-caret-bottom" style="color: #908b8b"></i></span>
              </div>
            </template>
            <div style="padding-left: 25px; padding-bottom: 20px">
              <div class="city_select_title">选择数据范围</div>
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                @change="handleCheckAllChange">全选</el-checkbox>
              <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                <el-checkbox style="margin-top: 17px" v-for="city in cities" :label="city.id" :key="city.id">{{ city.name
                }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-popover>
        </el-col>
        <el-col class="refresh" :span="2" @click="refresh">
          <img src="../../assets/img/icon-home-refresh.png" alt="" />
          <span>刷新</span>
        </el-col>
      </el-row>
    </div>
    <div class="container_top_accord">
      <div class="top_instr">
        <div class="hello">Hello，{{ information.name }}！</div>
        <!--            <div class="tobe">您有10条待办，请尽快处理哦～</div>-->
        <div class="dataInfo">
          <div class="grid-content">
            <img src="../../assets/img/icon-home-all.png" />
            <div class="data_accord">
              <div class="runNum">{{ elevatorData.totalCount || 0 }}</div>
              <div class="Public_fontcolor">电梯总量（台）</div>
            </div>
          </div>
          <div class="grid-content">
            <img src="../../assets/img/icon-home-tiring.png" />
            <div class="data_accord">
              <div class="runNum">{{ elevatorData.todayLockEventCount || 0 }}</div>
              <div class="Public_fontcolor">今日困人（起）</div>
            </div>
          </div>
          <div class="grid-content">
            <img src="../../assets/img/icon-home-help.png" />
            <div class="data_accord">
              <div class="runNum">{{ elevatorData.todayCallRecordCount || 0 }}</div>
              <div class="Public_fontcolor">今日一键呼救（次）</div>
            </div>
          </div>
          <div class="grid-content">
            <img style="width: 50px; height: 50px" src="../../assets/img/icon-home-feather.png" />
            <div class="data_accord">
              <div class="runNum">{{ elevatorData.toSignWorkOrderCount || 0 }}</div>
              <div class="Public_fontcolor">
                待签字工单数
                <el-tooltip class="item" effect="dark" content="维修加保养的待签字工单总数" placement="bottom">
                  <img style="width: 16px" src="../../assets/img/icon-pop-question.png" />
                </el-tooltip>
              </div>
            </div>
          </div>
          <!--              <el-row :gutter="24">-->
          <!--                <el-col :span="4"><div class="grid-content">-->

          <!--                </div></el-col>-->
          <!--                <el-col :span="4"><div class="grid-content">-->
          <!--                  <img src="../../assets/img/logo.png">-->
          <!--                  <div class="data_accord">-->
          <!--                    <div class="runNum">{{elevatorData.toInstallCount || 0}}</div>-->
          <!--                    <div class="Public_fontcolor">待安装（台）</div>-->
          <!--                  </div>-->
          <!--                </div></el-col>-->
          <!--                <el-col :span="4"><div class="grid-content">-->
          <!--                  <img src="../../assets/img/logo.png">-->
          <!--                  <div class="data_accord">-->
          <!--                    <div class="runNum">{{elevatorData.tryRunCount || 0}}</div>-->
          <!--                    <div class="Public_fontcolor">试运行（台）</div>-->
          <!--                  </div>-->
          <!--                </div></el-col>-->
          <!--              </el-row>-->
        </div>
      </div>
    </div>
    <!--设备数量及在线情况-->
    <div class="num_status">
      <!--可视化数据展示-->
      <div class="home_echart">
        <el-row :gutter="24">
          <!--故障告警数量-->
          <el-col :span="12">
            <div class="grid-content" style="height: 340px">
              <div class="Essential_title">
                <span class="title_name">故障告警数量</span>
                <el-radio-group class="date_select" size="small" @change="findDeviceErrorCount()" v-model="tabPositiont">
                  <el-radio-button label="0">今日</el-radio-button>
                  <el-radio-button label="1">近7日</el-radio-button>
                  <el-radio-button label="2">近30日</el-radio-button>
                </el-radio-group>
              </div>
              <div class="echart_box" v-if="faultAlarmsEchartShow">
                <div id="faultAlarmsEchart" style="width: 100%; height: 300px; margin: 0 auto"></div>
              </div>
              <div v-else style="height: 400px">
                <div class="default_img">
                  <img src="@/assets/img/img-tiring-pop-log.png" alt="" />
                  <div>暂无数据</div>
                </div>
              </div>
            </div>
          </el-col>

          <!--事件告警数量-->
          <el-col :span="12">
            <div class="grid-content" style="height: 340px">
              <div class="Essential_title">
                <span class="title_name">事件告警数量</span>
                <el-radio-group class="date_select" size="small" @change="getEventCount()" v-model="tabPositiontEvent">
                  <el-radio-button label="0">今日</el-radio-button>
                  <el-radio-button label="1">近7日</el-radio-button>
                  <el-radio-button label="2">近30日</el-radio-button>
                </el-radio-group>
              </div>
              <div class="echart_box" v-if="eventEchartShow">
                <div id="eventEchart" style="width: 100%; height: 300px; margin: 0 auto"></div>
              </div>
              <div v-else style="height: 400px">
                <div class="default_img">
                  <img src="@/assets/img/img-tiring-pop-log.png" alt="" />
                  <div>暂无数据</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24" style="margin-top: 24px">
          <!--困人报警统计-->
          <el-col :span="12">
            <div class="grid-content">
              <div class="Essential_title">
                <span class="title_name">困人报警统计</span>
                <!--0:今日,1:近7日,2:近30日，默认为1,示例值(1)-->
                <el-radio-group class="date_select" size="small" v-model="tabPositiontTiring" @change="getTiring()">
                  <!--<el-radio-button label="top">今日</el-radio-button>-->
                  <el-radio-button label="1">近7日</el-radio-button>
                  <el-radio-button label="2">近30日</el-radio-button>
                </el-radio-group>
              </div>
              <div class="echart_box" style="padding-right: 10px; margin-left: 50px" v-if="tiringAlarmEchartShow">
                <div id="tiringAlarmEchart" style="width: 100%; height: 400px; margin: 0 auto"></div>
              </div>
              <div v-else style="height: 400px">
                <div class="default_img">
                  <img src="@/assets/img/img-tiring-pop-log.png" alt="" />
                  <div>暂无数据</div>
                </div>
              </div>
            </div>
          </el-col>

          <!--一键呼救统计-->
          <el-col :span="12">
            <div class="grid-content">
              <div class="Essential_title">
                <span class="title_name">一键呼救统计</span>
                <el-radio-group class="date_select" size="small" v-model="tabPositionKey" @change="getKey()">
                  <!--<el-radio-button label="0">今日</el-radio-button>-->
                  <el-radio-button label="1">近7日</el-radio-button>
                  <el-radio-button label="2">近30日</el-radio-button>
                </el-radio-group>
              </div>
              <div class="echart_box" style="padding-right: 10px; margin-left: 50px" v-if="buttonHelpEchartShow">
                <div id="buttonHelpEchart" style="width: 100%; height: 400px; margin: 0 auto"></div>
              </div>
              <div v-else style="height: 400px">
                <div class="default_img">
                  <img src="@/assets/img/img-tiring-pop-log.png" alt="" />
                  <div>暂无数据</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="24" style="margin-top: 24px">
          <!--电梯运行数据统计-->
          <el-col :span="12">
            <div class="grid-content">
              <div class="Essential_title">
                <span class="title_name">电梯运行数据统计</span>
                <el-radio-group class="date_select" size="small" v-model="tabPositionType" @change="getType()">
                  <!--<el-radio-button label="0">今日</el-radio-button>-->
                  <el-radio-button label="1">近7日</el-radio-button>
                  <el-radio-button label="2">近30日</el-radio-button>
                </el-radio-group>
              </div>
              <div class="echart_box" style="padding-right: 10px; margin-left: 50px" v-if="elevatorStatusEchartShow">
                <div id="elevatorStatusEchart" style="width: 100%; height: 380px; margin: 0 auto"></div>
              </div>
              <div v-else style="height: 400px">
                <div class="default_img">
                  <img src="@/assets/img/img-tiring-pop-log.png" alt="" />
                  <div>暂无数据</div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content">
              <div class="Essential_title">
                <span class="title_name">困人故障原因分布图</span>
                <el-radio-group class="date_select" size="small" v-model="tabTrappedFault" @change="getTrappedFault()">
                  <!-- <el-radio-button label="0">今日</el-radio-button> -->
                  <el-radio-button label="1">近7日</el-radio-button>
                  <el-radio-button label="2">近30日</el-radio-button>
                </el-radio-group>
              </div>
              <div class="echart_box" v-if="trappedFaultShow">
                <div id="trappedFault" style="width: 100%; height: 400px; margin: 0 auto"></div>
              </div>
              <div v-else style="height: 400px">
                <div class="default_img">
                  <img src="@/assets/img/img-tiring-pop-log.png" alt="" />
                  <div>暂无数据</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24" style="margin-top: 24px">
          <!--电梯品牌分布图-->
          <el-col :span="12">
            <div class="grid-content">
              <div class="Essential_title">
                <span class="title_name">电梯品牌分布图</span>
                <!-- <el-radio-group
                  class="date_select"
                  v-model="tabPositiontBrand"
                  @change="getBrandCount()"
                >
                  <el-radio-button label="0">今日</el-radio-button>
                  <el-radio-button label="1">近7日</el-radio-button>
                  <el-radio-button label="2">近30日</el-radio-button>
                </el-radio-group> -->
              </div>
              <div class="echart_box" v-if="brandEchartShow">
                <div id="brandEchart" style="width: 100%; height: 400px; margin: 0 auto"></div>
              </div>
              <div v-else style="height: 400px">
                <div class="default_img">
                  <img src="@/assets/img/img-tiring-pop-log.png" alt="" />
                  <div>暂无数据</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog title="区域筛选" v-model="dialogFormVisible" style="height: 600px" width="1000px">
      <el-cascader class="cascader_width" v-model="optionsValue" :options="options" :props="optionProps"
        @change="handleChange">
        <template #default="{ node, data }">
          <span>{{ data.name }}</span>
          <span v-if="!node.isLeaf"></span>
        </template>
      </el-cascader>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { reactive, toRefs, onMounted, ref, unref, inject, nextTick } from 'vue'
import { regionDataPlus } from 'element-china-area-data'
import { getVillageList } from '../../api/elevatorMonitoring/index'
import {
  getElevatorData,
  getDeviceErrorCount,
  tiringCount,
  getCallRecordCountList,
  faultCount,
  keyCount,
  eleCause,
  typeCount,
  eventErrorCount,
  eleCount,
} from '../../api/home/index'
import { ElMessage } from 'element-plus'
import { l_storage } from '@/utils/storage'
export default {
  name: 'J_welcome',
  setup() {
    const state = reactive({
      tabPositiont: 0, //故障告警统计时间选择日期类型，0:今日,1:近7日,2:近30日，默认为1,示例值(1)
      tabPositiontEvent: 0, //事件告警统计时间选择日期类型，0:今日,1:近7日,2:近30日，默认为1,示例值(1)
      tabPositiontTiring: 1, //困人统计时间选择日期类型，0:今日,1:近7日,2:近30日，默认为1,示例值(1)
      tabPositionKey: 1, //一键呼救时间选择日期类型，0:今日,1:近7日,2:近30日，默认为1,示例值(1)
      tabPositionType: 1, //电梯运行数据统计选择日期类型，0:今日,1:近7日,2:近30日，默认为1,示例值(1)
      tabPositiontBrand: 1, //电梯品牌统计时间选择日期类型，0:今日,1:近7日,2:近30日，默认为1,示例值(1)
      tabTrappedFault: 1, //困人故障原因分布图时间选择日期类型，0:今日,1:近7日,2:近30日，默认为1,示例值(1)
      wyDeviceErrorCount: {
        total: 0,
        openCloseDoorErrorCount: 0,
      }, //故障告警统计
      faultAlarmsEchartShow: true, //故障告警显示
      eventEchartShow: true, //事件告警显示
      tiringAlarmEchartShow: true, //困人报警统计显示
      buttonHelpEchartShow: true, //一键呼救统计显示
      elevatorStatusEchartShow: true, //电梯运行数据统计显示
      brandEchartShow: true, //电梯品牌分布图
      trappedFaultShow: true, //困人故障原因分布图
      num: 4,
      dialogFormVisible: false,
      cityIdSelect: '',
      options: [], //省区省市区街道选择
      selectedOptions: [],
      optionsValue: [], //创建，编辑省区省市区街道默认选择
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        leaf: '',
      },
      elevatorData: {}, //电梯数量统计
      deviceData: {}, //设备数量统计
      OptionsCityAll: [],
      isShow: true,
      checkAll: true,
      checkedCities: [],
      cities: [],
      isIndeterminate: true,
      information: {},
      showCommunityList: '全选', //小区展示
    })
    const onRefresh = inject('reload')

    onMounted(() => {
      state.information = l_storage.get('information')
      console.log(state.information)
      methods.getCity()
    })
    const methods = {
      //全选
      handleCheckAllChange(val) {
        //反选
        if (state.checkedCities.length == state.cities.length) {
          state.checkedCities = []
        } else {
          state.checkedCities = []
          state.cities.forEach((item) => {
            state.checkedCities.push(item.id)
          })
          state.showCommunityList = '全选'
        }

        state.isIndeterminate = false

        console.log(state.checkedCities)
        methods.refreshData()
      },
      pieDataShow(data, stu) {
        //告警data数据判断
        let flag = false
        for (let item in data) {
          if (data[item] != 0) {
            flag = true
          }
        }
        state[stu] = flag
      },
      countDataShow(data, stu) {
        let flag = false
        if (data.length > 0) {
          flag = true
        }
        state[stu] = flag
      }, //统计数据判断
      //复选框点击
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length
        state.checkAll = checkedCount === state.cities.length
        state.isIndeterminate = checkedCount > 0 && checkedCount < state.cities.length
        console.log('复选框点击')
        console.log(state.checkedCities)
        let arr = []
        if (state.cities.length == state.checkedCities.length) {
        } else {
          state.cities.forEach((item) => {
            state.checkedCities.forEach((count) => {
              if (item.id == count) {
                arr.push(item.name)
              }
            })
          })
          state.showCommunityList = arr.join(' , ')
        }
        console.log(state.cities)
        methods.refreshData()
      },
      //刷新
      refresh() {
        console.log(111)
        onRefresh()
      },
      //电梯运行
      getType() {
        state.elevatorStatusEchartShow = true
        console.log('电梯运行数据')
        let myChart = null

        let params = {
          eleRunDateType: state.tabPositionType,
          villageIdList: state.checkedCities + '',
        }
        typeCount(params).then((res) => {
          console.log(res)
          if (res.code == 0) {
            let data = res.data
            methods.countDataShow(data, 'elevatorStatusEchartShow')
            if (!state.elevatorStatusEchartShow) {
              return
            }
            let state0 = []
            let state1 = []
            let state2 = []
            let state3 = []
            let xdata = []

            data.forEach((item, idx) => {
              // if (state.tabPositionType == 2 && new Date().getDate() % 2 == 0) {
              //   item.date.split('-')[1] % 2 == 0 ? data.splice(idx, 1) : ''
              // }
              // if (state.tabPositionType == 2 && new Date().getDate() % 2 != 0) {
              //   item.date.split('-')[1] % 2 == 0 ? '' : data.splice(idx, 1)
              // }
              state0.push(item.runMeter)
              state1.push(item.runMins)
              state2.push(item.runCount)
              state3.push(item.doorToggleCount)
              xdata.push(item.date)
            })
            console.log(data)

            nextTick(() => {
              console.log(document.getElementById('elevatorStatusEchart'))

              myChart = echarts.init(document.getElementById('elevatorStatusEchart'))
              // 绘制图表
              myChart.setOption({
                animation: true,
                tooltip: {
                  trigger: 'axis',
                  backgroundColor: 'rgba(0, 0, 0, 0.65)',
                  borderWidth: '0',
                  textStyle: {
                    color: '#FFFFFF',
                  },
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: xdata,
                },
                yAxis: [
                  {
                    type: 'value',
                    splitNumber: 5,
                    axisLabel: {
                      textStyle: {
                        color: '#a8aab0',
                        fontStyle: 'normal',
                        fontFamily: '微软雅黑',
                        fontSize: 12,
                      },
                    },
                    axisLine: {
                      show: false,
                    },
                    axisTick: {
                      show: false,
                    },
                    splitLine: {
                      show: true,
                      lineStyle: {
                        color: '#E5E9ED',
                        // 	opacity:0.1
                      },
                    },
                  },
                ],
                series: [
                  {
                    name: '运行里程（米）',
                    symbolSize: 2,
                    type: 'line',
                    color: 'rgba(250, 211, 58, 1)',
                    itemStyle: {
                      color: 'rgba(250, 211, 58, 1)',
                      lineStyle: {
                        color: 'rgba(250, 211, 58, 1)',
                        width: 1,
                      },
                    },
                    data: state0,
                  },
                  {
                    name: '运行时间（分钟）',
                    symbolSize: 2,
                    type: 'line',
                    itemStyle: {
                      normal: {
                        color: 'rgba(236, 104, 130, 1)',
                        lineStyle: {
                          color: 'rgba(236, 104, 130, 1)',
                          width: 1,
                        },
                      },
                    },
                    data: state1,
                  },
                  {
                    name: '运行次数',
                    symbolSize: 2,
                    type: 'line',
                    itemStyle: {
                      normal: {
                        color: 'rgba(67, 187, 96, 1)',
                        lineStyle: {
                          color: 'rgba(67, 187, 96, 1)',
                          width: 1,
                        },
                      },
                    },
                    data: state2,
                  },
                  {
                    name: '开关门次数',
                    symbolSize: 2,
                    type: 'line',
                    itemStyle: {
                      normal: {
                        color: 'rgba(24, 144, 255, 1)',
                        lineStyle: {
                          color: 'rgba(24, 144, 255, 1)',
                          width: 1,
                        },
                      },
                    },
                    data: state3,
                  },
                ],
              })
              document
                .getElementById('elevatorStatusEchart')
                .setAttribute('_echarts_instance_', '')
              window.onresize = function () {
                //自适应大小
                myChart.resize()
              }
            })
          }
        })
      },
      //一键呼救
      getKey() {
        console.log('一键呼救统计')
        let myChart = null

        let params = {
          callRecordDateType: state.tabPositionKey,
          villageIdList: state.checkedCities + '',
        }
        keyCount(params).then((res) => {
          console.log(res)
          if (res.code == 0) {
            methods.countDataShow(res.data, 'buttonHelpEchartShow')
            if (!state.buttonHelpEchartShow) {
              return
            }
            let xdata = []
            let ydata = []
            var arr = res.data
            arr.forEach((item) => {
              xdata.push(item.date)
              ydata.push(item.count)
            })
            nextTick(() => {
              myChart = echarts.init(document.getElementById('buttonHelpEchart'))
              // 绘制图表
              myChart.setOption({
                animation: true,
                color: 'rgba(236, 104, 130, 1)',

                tooltip: {
                  trigger: 'axis',
                  backgroundColor: 'rgba(0, 0, 0, 0.65)',
                  borderWidth: '0',
                  textStyle: {
                    color: '#FFFFFF',
                  },
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: xdata,
                },
                yAxis: {
                  type: 'value',
                },
                series: [
                  {
                    name: '一键呼救',
                    smooth: true, //是否平滑展示
                    type: 'line',
                    areaStyle: {
                      normal: {
                        //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: 'rgba(236, 104, 130, 1)',
                          },
                          {
                            offset: 1,
                            color: 'rgba(225, 246, 244, 0)',
                          },
                        ]),
                      },
                    }, //区域颜色渐变
                    data: ydata,
                  },
                ],
              })
            })
            document
              .getElementById('buttonHelpEchart')
              .setAttribute('_echarts_instance_', '')
            window.onresize = function () {
              //自适应大小
              myChart.resize()
            }
          }
        })
      },
      //困人
      getTiring() {
        state.tiringAlarmEchartShow = true
        console.log('困人报警统计')
        let myChart = null

        let params = {
          lockEventDateType: state.tabPositiontTiring,
          villageIdList: state.checkedCities + '',
        }
        console.log(params)
        tiringCount(params).then((res) => {
          console.log(res)
          if (res.code == 0) {
            methods.countDataShow(res.data, 'tiringAlarmEchartShow')
            if (!state.tiringAlarmEchartShow) {
              return
            }
            let xdata = []
            let ydata = []
            var arr = res.data
            arr.forEach((item) => {
              xdata.push(item.date)
              ydata.push(item.count)
            })
            nextTick(() => {
              console.log(document.getElementById('tiringAlarmEchart'))
              myChart = echarts.init(document.getElementById('tiringAlarmEchart'))
              // 绘制图表
              myChart.setOption({
                animation: true,
                color: 'rgba(71, 188, 197, 1)',
                tooltip: {
                  trigger: 'axis',
                  backgroundColor: 'rgba(0, 0, 0, 0.65)',
                  borderWidth: '0',
                  textStyle: {
                    color: '#FFFFFF',
                  },
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: xdata,
                },
                yAxis: {
                  type: 'value',
                },
                series: [
                  {
                    name: '困人报警',
                    smooth: true, //是否平滑展示
                    type: 'line',
                    areaStyle: {
                      normal: {
                        //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: 'rgba(228, 246, 245, 1)',
                          },
                          {
                            offset: 1,
                            color: 'rgba(225, 246, 244, 0)',
                          },
                        ]),
                      },
                    }, //区域颜色渐变
                    data: ydata,
                  },
                ],
              })
            })
            document
              .getElementById('tiringAlarmEchart')
              .setAttribute('_echarts_instance_', '')
            window.onresize = function () {
              //自适应大小
              myChart.resize()
            }
          }
        })
      },
      //获取小区列表
      getCity() {
        let params = {
          needElevator: 0,
        }
        getVillageList(params).then((res) => {
          if (res.code == 0) {
            state.options = res.data
            state.cities = res.data
            res.data.forEach((item) => {
              state.checkedCities.push(item.id)
            })
            state.isIndeterminate = false
            if (res.data.length == 0) {
              ElMessage.warning('当前用户下的区域列表为空')
              return false
            }
            methods.refreshData()
          } else {
            ElMessage.warning(res.msg)
          }
        })
      },
      //刷新数据
      refreshData() {
        methods.findElevatorData()
        methods.findDeviceErrorCount()
        methods.getTiring()
        methods.getKey()
        methods.getType()
        methods.getEventCount()
        methods.getBrandCount()
        methods.getTrappedFault() //困人故障原因分布图
      },
      //物业电梯数量及今日困人、今日一键呼救统计
      findElevatorData() {
        console.log('物业电梯数量及今日困人、今日一键呼救统计')
        console.log(state.checkedCities)
        let params = { villageIdList: state.checkedCities + '' }

        getElevatorData(params).then((res) => {
          console.log(res)
          if (res.code == 0) {
            state.elevatorData = res.data
          } else {
            ElMessage.warning(res.msg)
          }
        })
      },

      //故障告警统计
      findDeviceErrorCount() {
        state.faultAlarmsEchartShow = true
        console.log('故障告警统计')
        console.log(state.checkedCities)

        let myChart = null

        let params = {
          deviceErrorDateType: state.tabPositiont,
          villageIdList: state.checkedCities + '',
        }

        getDeviceErrorCount(params).then((res) => {
          if (res.code == 0) {
            let data = res.data
            methods.pieDataShow(data, 'faultAlarmsEchartShow')
            // 绘制图表
            if (!state.faultAlarmsEchartShow) {
              return
            }
            // myChart.setOption({
            //   color: [
            //     'rgba(151, 95, 228, 1)',
            //     'rgba(58, 160, 255, 1)',
            //     'rgba(54, 203, 203, 1)',
            //     'rgba(77, 203, 115, 1)',
            //     'rgba(242, 99, 123, 1)',
            //     'rgba(250, 211, 55, 1)',
            //     'rgba(255, 127, 46, 1)',
            //     'rgba(254, 186, 43, 1)',
            //     'rgba(115, 175, 255, 1)',
            //     'rgba(217, 119, 255, 1)',
            //   ],
            //   tooltip: {
            //     trigger: 'item',
            //   },
            //   legend: {
            //     right: '0%',
            //     top: 'middle',
            //     width: 360, //给整个图例组件这只宽度,如果不设置,图例将根据容器大小自适应
            //     icon: 'circle',
            //     formatter: function (name) {
            //       let value
            //       switch (name) {
            //         case '开门故障':
            //           value = data.openDoorErrorCount

            //           break
            //         case '关门故障':
            //           value = data.closeDoorErrorCount

            //           break
            //         case '区域外停梯':
            //           value = data.stopOutsideTheAreaCount

            //           break
            //         case '轿厢意外移动':
            //           value = data.moveUnexpectedlyCount

            //           break
            //         case '开门走梯':
            //           value = data.openDoorWalkCount

            //           break
            //         case '冲顶':
            //           value = data.rushTopCount

            //           break
            //         case '蹲底':
            //           value = data.squatBottomCount

            //           break
            //         case '反复开关门故障':
            //           value = data.repeatOpenCloseDoorErrorCount

            //           break
            //         case '主电源断电':
            //           value = data.mainPowerFailureCount

            //           break
            //       }
            //       var arr = ['{a|' + name + '  |  ' + value + '}']
            //       return arr.join('\n')
            //     },
            //     textStyle: {
            //       //rich放在textStyle里面
            //       rich: {
            //         a: {
            //           color: '#000000',
            //           lineHeight: 10,
            //           width: 90,
            //         },
            //         b: {
            //           backgroundColor: {
            //             image: 'xxx/xxx.jpg',
            //           },
            //           height: 40,
            //         },
            //         x: {
            //           fontSize: 18,
            //           fontFamily: 'Microsoft YaHei',
            //           borderColor: '#449933',
            //           borderRadius: 4,
            //         },
            //       },
            //     },
            //   },
            //   series: [
            //     {
            //       name: 'Access From',
            //       center: ['30%', '50%'],
            //       type: 'pie',
            //       radius: ['30%', '50%'],
            //       avoidLabelOverlap: false,
            //       label: {
            //         show: false,
            //         position: 'center',
            //       },
            //       emphasis: {
            //         label: {
            //           show: true,
            //           fontSize: '20',
            //           fontWeight: 'bold',
            //         },
            //       },
            //       labelLine: {
            //         show: false,
            //       },
            //       data: [
            //         { value: data.openDoorErrorCount, name: '开门故障' },
            //         { value: data.closeDoorErrorCount, name: '关门故障' },
            //         { value: data.stopOutsideTheAreaCount, name: '区域外停梯' },
            //         { value: data.moveUnexpectedlyCount, name: '轿厢意外移动' },
            //         { value: data.openDoorWalkCount, name: '开门走梯' },
            //         { value: data.rushTopCount, name: '冲顶' },
            //         { value: data.squatBottomCount, name: '蹲底' },
            //         { value: data.repeatOpenCloseDoorErrorCount, name: '反复开关门故障' },
            //         { value: data.mainPowerFailureCount, name: '主电源断电' },
            //       ],
            //     },
            //   ],
            // })
            nextTick(() => {
              myChart = echarts.init(document.getElementById('faultAlarmsEchart'))

              let dataRes = [
                // { value: data.openDoorErrorCount, name: '开门故障' },
                // { value: data.closeDoorErrorCount, name: '关门故障' },
                // { value: data.emergencyStopCount, name: '急停数量' },

                // { value: data.openDoorWalkCount, name: '开门走梯' },
                // { value: data.rushTopCount, name: '冲顶' },
                // { value: data.squatBottomCount, name: '蹲底' },
                // { value: data.mainPowerFailureCount, name: '主电源断电' },
                // { value: data.stopOutsideTheAreaCount, name: '区域外停梯' },
                // { value: data.overSpeedCount, name: '超速数量' },
                // { value: data.errorCount, name: '轿厢意外移动' },
                // { value: data.repeatOpenCloseDoorErrorCount, name: '反复开关门故障' },
              ]
              let obgRes = {}
              data.forEach(item => {
                dataRes.push({
                  value: item.errorCount, name: item.errorTypeStr
                })
                obgRes[item.errorTypeStr] = item.errorCount
              })


              myChart.setOption({
                color: [
                  'rgba(77, 203, 115, 1)',
                  'rgba(242, 99, 123, 1)',
                  'rgba(250, 211, 55, 1)',
                  'rgba(255, 127, 46, 1)',
                  'rgba(254, 186, 43, 1)',
                  'rgba(115, 175, 255, 1)',
                  'rgba(217, 119, 255, 1)',
                  'rgba(151, 95, 228, 1)',
                  'rgba(58, 160, 255, 1)',
                  'rgba(54, 203, 203, 1)',
                  'rgba(5, 143, 35, 1)',
                ],
                legend: [
                  {
                    selectedMode: false,
                    //图例
                    right: '0%',
                    top: '25%',
                    itemGap: 15, // 横向布局时为水平间隔，纵向布局时为纵向间隔
                    itemWidth: 12, // 图例图形宽度
                    itemHeight: 8,
                    orient: 'vertical',
                    icon: 'circle',
                    textStyle: {
                      fontSize: 14, //字体大小
                      color: 'rgba(0, 0, 0, 0.65)', //字体颜色
                    },
                    formatter(name) {
                      let value = obgRes[name] || 0
                      // switch (name) {
                      //   case '开门故障':
                      //     value = data.openDoorErrorCount || 0

                      //     break
                      //   case '急停数量':
                      //     value = data.emergencyStopCount || 0

                      //     break
                      //   case '关门故障':
                      //     value = data.closeDoorErrorCount || 0

                      //     break
                      //   case '开门走梯':
                      //     value = data.openDoorWalkCount || 0

                      //     break
                      //   case '冲顶':
                      //     value = data.rushTopCount || 0

                      //     break
                      // }
                      return `${name} | ${value}`
                    },
                    data: dataRes.slice(0, 5),
                  },
                  {
                    selectedMode: false,
                    //图例
                    right: '26%',
                    top: '25%',
                    itemGap: 15, // 横向布局时为水平间隔，纵向布局时为纵向间隔
                    itemWidth: 12, // 图例图形宽度
                    itemHeight: 8,
                    orient: 'vertical',
                    icon: 'circle',
                    textStyle: {
                      fontSize: 14, //字体大小
                      color: 'rgba(0, 0, 0, 0.65)', //字体颜色
                    },
                    formatter(name) {
                      let value = obgRes[name] || 0
                      // switch (name) {
                      //   case '蹲底':
                      //     value = data.squatBottomCount || 0

                      //     break
                      //   case '区域外停梯':
                      //     value = data.stopOutsideTheAreaCount || 0

                      //     break
                      //   case '主电源断电':
                      //     value = data.mainPowerFailureCount || 0

                      //     break
                      //   case '超速数量':
                      //     value = data.overSpeedCount || 0

                      //     break
                      //   case '轿厢意外移动':
                      //     value = data.errorCount || 0

                      //     break
                      //   case '反复开关门故障':
                      //     value = data.repeatOpenCloseDoorErrorCount || 0

                      //     break
                      // }
                      return `${name} | ${value}`
                    },
                    data: dataRes.slice(5),
                  },
                ],
                series: [
                  {
                    name: '',
                    type: 'pie',
                    radius: ['30%', '40%'],
                    center: ['25%', '50%'],
                    avoidLabelOverlap: false,
                    label: {
                      show: false,
                      position: 'center',
                      formatter: ['{c|{b}}', '{a|{d}%}'].join('\n\n'),
                      rich: {
                        a: {
                          fontSize: 18,
                          fontWeight: '700',
                          color: 'rgba(0, 0, 0, 0.85)',
                        },
                        c: {
                          fontSize: 12,
                        },
                      },
                      emphasis: {
                        show: true,
                        textStyle: {
                          fontSize: '16',
                          fontWeight: 'bold',
                          color: 'rgba(0, 0, 0, 0.45)',
                        },
                      },
                    },
                    labelLine: {
                      show: false,
                    },
                    data: dataRes,
                  },
                ],
              })
              let max = 0
              let index = 0
              dataRes.map((r, j) => {
                if (max < r.value) {
                  index = j
                  max = r.value
                }
              })
              myChart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: index,
              })
              myChart.on('mouseover', function (e) {
                if (e.dataIndex != index) {
                  myChart.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: index,
                  })
                }
              })

              myChart.on('mouseout', function (e) {
                index = e.dataIndex

                myChart.dispatchAction({
                  type: 'highlight',
                  seriesIndex: 0,
                  dataIndex: e.dataIndex,
                })
              })
              window.onresize = function () {
                //自适应大小
                myChart.resize()
              }
              document
                .getElementById('faultAlarmsEchart')
                .setAttribute('_echarts_instance_', '')
            })
          } else {
            ElMessage.warning(res.msg)
          }
        })
      },
      //事件告警统计
      getEventCount() {
        state.eventEchartShow = true
        console.log('事件告警统计')
        let myChart = null

        let params = {
          eventErrorDateType: state.tabPositiontEvent,
          villageIdList: state.checkedCities + '',
        }
        console.log(params)
        eventErrorCount(params).then((res) => {
          if (res.code == 0) {
            let data = res.data
            methods.pieDataShow(data, 'eventEchartShow')
            if (!state.eventEchartShow) {
              return
            }
            let dataRess = [
            ]
            let obgRes = {}
            data.forEach(item => {
              dataRess.push({
                value: item.errorCount, name: item.errorTypeStr
              })
              obgRes[item.errorTypeStr] = item.errorCount
            })

            nextTick(() => {
              myChart = echarts.init(document.getElementById('eventEchart'))
              // 绘制图表
              myChart.setOption({
                color: [
                  'rgba(58, 160, 255, 1)',
                  'rgba(151, 95, 228, 1)',
                  'rgba(77, 203, 115, 1)',
                  'rgba(250, 211, 55, 1)',
                ],
                legend: {
                  //图例
                  selectedMode: false,
                  right: '2%',
                  top: '30%',
                  itemGap: 15, // 横向布局时为水平间隔，纵向布局时为纵向间隔
                  itemWidth: 15, // 图例图形宽度
                  icon: 'circle',
                  itemHeight: 8,
                  orient: 'vertical',
                  textStyle: {
                    fontSize: 14, //字体大小
                    color: 'rgba(0, 0, 0, 0.65)', //字体颜色
                  },
                  formatter(name) {
                    let value = obgRes[name]
                    return `${name} | ${value}`
                  },
                },

                series: [
                  {
                    name: '',
                    type: 'pie',
                    radius: ['30%', '40%'],
                    center: ['42%', '50%'],
                    avoidLabelOverlap: false,
                    label: {
                      normal: {
                        show: false,
                        position: 'center',
                        formatter: ['{c|{b}}', '{a|{d}%}'].join('\n\n'),
                        rich: {
                          a: {
                            fontSize: 18,
                            fontWeight: '700',
                            color: 'rgba(0, 0, 0, 0.85)',
                          },
                          c: {
                            fontSize: 12,
                            color: 'rgba(0, 0, 0, 0.45)',
                          },
                        },
                      },
                      emphasis: {
                        show: true,
                        textStyle: {
                          fontSize: '16',
                          fontWeight: 'bold',
                        },
                      },
                    },
                    labelLine: {
                      normal: {
                        show: false,
                      },
                    },
                    data: dataRess,
                  },
                ],
              })

              let max = 0
              let index = 0
              // let dataRes = [
              //   { value: data.cylinderMonitorEventCount, name: '钢瓶检测' },
              //   { value: data.electromobileMonitorEventCount, name: '电动车检测' },
              //   { value: data.longTimeBlockDoorEventCount, name: '长时间挡门' },
              //   { value: data.repeatOpenCloseDoorEventCount, name: '反复开关门' },
              //   { value: data.cylinderMonitorEventCount, name: '钢瓶检测' },
              //   { value: data.electromobileMonitorEventCount, name: '电动车检测' },
              //   { value: data.longTimeBlockDoorEventCount, name: '长时间挡门' },
              //   { value: data.repeatOpenCloseDoorEventCount, name: '反复开关门' },
              // ]
              dataRess.map((r, j) => {
                if (max < r.value) {
                  index = j
                  max = r.value
                }
              })

              myChart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: index,
              })
              myChart.on('mouseover', function (e) {
                if (e.dataIndex != index) {
                  myChart.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: index,
                  })
                }
              })

              myChart.on('mouseout', function (e) {
                index = e.dataIndex

                myChart.dispatchAction({
                  type: 'highlight',
                  seriesIndex: 0,
                  dataIndex: e.dataIndex,
                })
              })
              window.onresize = function () {
                //自适应大小
                myChart.resize()
              }
              document
                .getElementById('eventEchart')
                .setAttribute('_echarts_instance_', '')
            })
          } else {
            ElMessage.warning(res.msg)
          }
        })
      },
      //电梯品牌统计
      getBrandCount() {
        state.brandEchartShow = true
        console.log('电梯品牌统计')

        let params = {
          lockEventDateType: state.tabPositiontBrand,
          villageIdList: state.checkedCities + '',
        }
        eleCount(params).then((res) => {
          if (res.code == 0) {
            let data = res.data
            let arr = []
            let dataCount = 0
            data.forEach((item) => {
              dataCount += item.count
              arr.push({ value: item.count, name: item.brandName })
            })
            console.log(dataCount)
            if (dataCount == 0) {
              state.brandEchartShow = false
              return
            }
            nextTick(() => {
              let myChart = echarts.init(document.getElementById('brandEchart'))
              // 绘制图表
              myChart.setOption({
                color: ['#3AA0FF', '#36CBCB', '#4DCB73', '#FAD337', '#F2637B', '#975FE4'],
                legend: {
                  selectedMode: false,
                  //图例
                  right: '10%',
                  top: 'middle',
                  itemGap: 15, // 横向布局时为水平间隔，纵向布局时为纵向间隔
                  itemWidth: 12, // 图例图形宽度
                  icon: 'circle',
                  itemHeight: 8,
                  orient: 'vertical',
                  textStyle: {
                    fontSize: 14, //字体大小
                    color: 'rgba(0, 0, 0, 0.65)', //字体颜色
                  },
                  formatter(name) {
                    let data = arr.filter((item) => item.name == name)
                    console.log(data)
                    return `${name} | ${data[0].value}`
                  },
                  // data:['钢瓶检测','电动车检测','长时间挡门','反复开关门',],
                },
                series: [
                  {
                    name: '',
                    type: 'pie',
                    radius: ['30%', '40%'],
                    center: ['23%', '50%'],
                    avoidLabelOverlap: false,

                    label: {
                      normal: {
                        show: false,
                        position: 'center',
                        formatter: ['{c|{b}}', '{a|{d}%}'].join('\n\n'),
                        rich: {
                          a: {
                            fontSize: 18,
                            fontWeight: '700',
                            color: 'rgba(0, 0, 0, 0.85)',
                          },
                          c: {
                            fontSize: 12,
                            color: 'rgba(0, 0, 0, 0.45)',
                          },
                        },
                      },
                      emphasis: {
                        show: true,
                        textStyle: {
                          fontSize: '16',
                          fontWeight: 'bold',
                        },
                      },
                    },
                    labelLine: {
                      normal: {
                        show: false,
                      },
                    },
                    data: arr,
                  },
                ],
              })
              let max = 0
              let index = 0
              arr.map((r, j) => {
                if (max < r.value) {
                  index = j
                  max = r.value
                }
              })
              myChart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: index,
              })
              myChart.on('mouseover', function (e) {
                if (e.dataIndex != index) {
                  myChart.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: index,
                  })
                }
              })

              myChart.on('mouseout', function (e) {
                index = e.dataIndex

                myChart.dispatchAction({
                  type: 'highlight',
                  seriesIndex: 0,
                  dataIndex: e.dataIndex,
                })
              })
              document
                .getElementById('brandEchart')
                .setAttribute('_echarts_instance_', '')
              window.onresize = () => {
                //自适应大小
                myChart.resize()
              }
            })
          } else {
            state.brandEchartShow = false
            ElMessage.warning(res.msg)
          }
        })
      },
      //困人故障原因分布图
      getTrappedFault() {
        state.trappedFaultShow = true
        console.log('困人故障原因分布图')

        let params = {
          queryDateType: state.tabTrappedFault,
          villageIdList: state.checkedCities + '',
        }
        eleCause(params).then((res) => {
          if (res.code == 0) {
            let data = res.data
            let arr = []
            let dataCount = 0
            data.forEach((item) => {
              dataCount += item.count
              arr.push({ value: item.count, name: item.x })
            })
            console.log(dataCount)
            if (dataCount == 0) {
              state.trappedFaultShow = false
              return
            }
            nextTick(() => {
              let myChart = echarts.init(document.getElementById('trappedFault'))
              // 绘制图表
              myChart.setOption({
                color: [
                  '#4DCB73',
                  '#FAD337',
                  '#F2637B',
                  '#36CBCB',
                  '#975FE4',
                  '#3AA0FF',
                  '#fa8036',
                ],
                legend: {
                  selectedMode: false,
                  //图例
                  right: '15%',
                  top: 'middle',
                  itemGap: 15, // 横向布局时为水平间隔，纵向布局时为纵向间隔
                  itemWidth: 12, // 图例图形宽度
                  icon: 'circle',
                  itemHeight: 8,
                  orient: 'vertical',
                  textStyle: {
                    fontSize: 14, //字体大小
                    color: 'rgba(0, 0, 0, 0.65)', //字体颜色
                  },
                  formatter(name) {
                    let data = arr.filter((item) => item.name == name)
                    console.log(data)
                    return `${name} | ${data[0].value}`
                  },
                  // data:['钢瓶检测','电动车检测','长时间挡门','反复开关门',],
                },
                series: [
                  {
                    name: '',
                    type: 'pie',
                    radius: ['30%', '40%'],
                    center: ['30%', '50%'],
                    avoidLabelOverlap: false,

                    label: {
                      normal: {
                        show: false,
                        position: 'center',
                        formatter: ['{c|{b}}', '{a|{d}%}'].join('\n\n'),
                        rich: {
                          a: {
                            fontSize: 18,
                            fontWeight: '700',
                          },
                          c: {
                            fontSize: 12,
                          },
                        },
                      },
                      emphasis: {
                        show: true,
                        textStyle: {
                          fontSize: '16',
                          fontWeight: 'bold',
                        },
                      },
                    },
                    labelLine: {
                      normal: {
                        show: false,
                      },
                    },
                    data: arr,
                  },
                ],
              })
              let max = 0
              let index = 0
              arr.map((r, j) => {
                if (max < r.value) {
                  index = j
                  max = r.value
                }
              })
              myChart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: index,
              })
              myChart.on('mouseover', function (e) {
                if (e.dataIndex != index) {
                  myChart.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: index,
                  })
                }
              })

              myChart.on('mouseout', function (e) {
                index = e.dataIndex

                myChart.dispatchAction({
                  type: 'highlight',
                  seriesIndex: 0,
                  dataIndex: e.dataIndex,
                })
              })
              document
                .getElementById('trappedFault')
                .setAttribute('_echarts_instance_', '')
              window.onresize = () => {
                //自适应大小
                myChart.resize()
              }
            })
          } else {
            ElMessage.warning(res.msg)
          }
        })
      },
      handlesee() {
        state.dialogFormVisible = true
      },
      cityValueChange(value) {
        console.log(value)
      },
    }

    return {
      ...toRefs(state),
      ...methods,
    }
  },
}
</script>
<style></style>

<style scoped lang="stylus">
>>>.el-popover.el-popper {
  padding-left: 25px !important;
  padding-bottom: 20px !important;
}
.city_select_title{
    margin  8px 0 10px
    font-weight 600
    color #000
    font-size: 14px;
    position relative
}
.city_select_title::after{
    content ""
    width : 3px;
    height: 15px;
    background: #0D70FB;
    position absolute
    left -8px
    top 2px
}
.container{
    font-size: 14px;
    width: 100%;

    .container_top_city{
      padding: 17px 36px 0 24px;
        .current_city{
            color: $base-blackfont45
        }

        .city_select{
          color: $base-blackfont85
          cursor pointer
        }
        .refresh{
            text-align right;
            color: $base-blackfont65
            cursor pointer
          img{
            margin-bottom: -3px;
            margin-right: 8px;
          }
        }
    }
    .container_top_accord{
      @media screen and (max-width:1500px) {
        .grid-content{
        margin-right: 30px;

        }
      }
      @media screen and (min-width:1501px) {
        .grid-content{
        margin-right: 60px;

        }
      }
        .grid-content{
            position relative
            height 100%
            border-radius: 4px;
            min-height: 36px;
            display flex;
            align-items center
            justify-content center
            //padding 16px 0

            .data_accord{
              display: flex;
    flex-direction: column;
                margin-left 24px
                .Public_fontcolor{font-size: 14px;text-align: left; color rgba(0, 0, 0, 0.45);display:flex;align-items:center;img{cursor:pointer}}
                .runNum{text-align: left;font-size: 20px;font-weight: bold;}
            }
        }
    }
   .num_status{
       .devices_num{
           color $base-blackfont85
           padding 16px 24px
           border-bottom 1px solid rgba(220, 229, 240, 1)
           background-color $base-fontfff
           margin-top 24px
           font-weight bold
       }
       .num_status_whole{
           padding:28px 0
           background-color $base-fontfff
           .num_status_see{
               display: grid;
               grid-template-columns: 25% 25% 25% 25%;
               grid-template-rows: 108px;
               grid-row-gap: 1px
               .num_status_main{
                   background-color  $base-fontfff
                   border-right 1px solid rgba(234, 238, 245, 1)
                   padding 0 60px
                   box-sizing border-box
                   .name_terminal{
                       margin-top 10px
                   }
                   .total_equipment{
                       color $base-blackfont65
                       font-size 12px
                       margin-top 8px
                   }
                   .terminal_progress{
                       width:100%
                       margin-top 13px
                   }
                   .online_status{
                       color $base-blackfont45
                       font-size 12px
                       margin-top 7px
                       padding-right 60px
                       width:100%
                       .flex_right{
                           float right
                       }
                   }

               }
               .num_status_main:first-child{
                   padding 0 60px 0 32px
               }
               .num_status_main:last-child{
                   border none
               }
           }
       }
   }
    .home_echart{
        margin-top -10px
        padding: 0 24px
        box-sizing: border-box
        .grid-content{
            position relative
            border-radius: 4px;
            height 100%
            box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.06);
            background-color $base-fontfff
        }
        .Essential_title{
            position relative
            height 56px
            line-height 56px
            .title_name{
                font-weight bold
                color $base-blackfont85
                margin-left 35px
            }
            .title_name::after{
              content ""
              width : 3px;
              height: 15px;
              background: #0D70FB;
              position absolute
              top 20px
              left 24px
            }
            .date_select{
                float right
                margin-top 10px
                font-size 12px
                margin-right 24px
            }
            >>>.el-radio-button__inner{
                font-size 12px
            }
        }
        .echart_box{
            padding-right 100px
            box-sizing border-box
        }
    }
}
>>>.el-radio-button__original-radio:checked+.el-radio-button__inner{
  background-color #fff !important
  color #0D70FB !important
  border-color #0D70FB !important
}
>>>.el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background-color #fff !important
  color #0D70FB !important
  border-color #0D70FB !important
}
>>>.el-cascader{
  width:100%
}
>>>.el-input{
    width:100%
}
>>>.el-col-4 {
    max-width: 20%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0  20%;
}
  >>>.el-dialog__body{
    padding 20px 50px 400px
  }
  @media screen and (max-width:1500px) {
    .top_instr{
      background url("../../assets/img/img-home-pic-2.png") no-repeat

    }
  }
   @media screen and (min-width:1501px) {
    .top_instr{
        background url("../../assets/img/img-home-pic.png") no-repeat
    }
  }
    .top_instr{

      background-size:100% 100%
      width 100%
      height 230px
      padding 48px 73px
      box-sizing: border-box;
      .hello{
        font-size: 20px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
      .tobe{
        font-size: 14px;
        font-family: PingFangSC;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 16px;
      }
      .dataInfo{
        display flex
        margin-top: 44px;
      }
    }
//.el-popper.is-light {
//  transform: translate(309px, 108px) !important;
//}
//.el-popper__arrow, .el-popper__arrow::before {
//  transform: translate(33px, 0px) !important;
//}
.default_img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align:center
    color:#8997a0
    img{
      width:50%

    }
}
>>>.el-checkbox{
  width 150px
}
.item_title{
    display: inline-block;
    text-overflow: ellipsis;
    width: 260px;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: bottom;
}
</style>
