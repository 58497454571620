/**
 *
 * axios封装
 * 2021.03.25
 ***/
import axios from 'axios'
import { ElMessage, ElLoading } from 'element-plus'
import { l_storage } from '@/utils/storage' //lostorage存储封装
import router from '@/router' //路由

let options, loading
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_AD_BASE_API,
  withCredentials: false,
  // timeout:20000,
})
// request拦截器
service.interceptors.request.use(
  (config) => {
    options = {
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'transparent',
    }
    loading = ElLoading.service(options)
    // 是否需要设置 token
    let headers = {
      userId: '',
      token: '',
      Authorization: '',
      SYSTEM_ID: 5,
      // "responseType": 'blob',
    }
    let information = l_storage.get('information')
    if (information) {
      // headers.userId=information.userLoginInfo.userId
      headers.userId = information.id
      headers.token = information.token
      headers.Authorization = information.token
    }
    config.headers['Authorization'] = 'Bearer ' + headers.Authorization // 让每个请求携带自定义token 请根据实际情况自行修改
    config.headers['token'] = headers.token // 让每个请求携带自定义token 请根据实际情况自行修改
    config.headers['USER_ID'] = headers.userId
    // config.headers['Content-Type'] =  'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
    config.headers['SYSTEM_ID'] = 5
    // config.headers['responseType'] =  'blob'
    return config
  },
  (error) => {
    console.log(error)
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    loading.close()
    // 未设置状态码则默认成功状态
    //console.log("service.interceptors.response",res)
    const code = res.data.code || 200 || 0
    // console.log("service.interceptors.response.data.code",code)
    // 获取错误信息
    const message = res.data.msg || res.data.message
    if (code === 403) {
      ElMessage.warning('登录超时，请重新登录！')
      setTimeout(function() {
        router.push({ path: '/' })
      }, 3000)
      return
    } else if (code === 505) {
      ElMessage.warning('未登陆，请重新登录！')
      setTimeout(function() {
        router.push({ path: '/' })
      }, 3000)
      return
    } else if (code === 10013 || code === 10019 || code === 10025) {
      ElMessage({
        message: message,
        type: 'error',
      })
      setTimeout(function() {
        router.push({ path: '/' })
      }, 3000)
      return
    } else if (code === 563 || code === 564) {
      return res
    } else if (code === 500) {
      ElMessage({
        message: message,
        type: 'error',
      })
      return Promise.reject(new Error(message))
    }
    // else if (code !== 0 && code !== "0") {
    //     ElMessage.warning(message);
    //     return Promise.reject('error')
    // }
    else {
      return res.data
    }
  },
  (error) => {
    console.log('err' + error)
    ElMessage.warning(error.message)
    return Promise.reject(error)
  }
)

export default service
