/**
 *
 * 全局注入
 * 2021.03.26
 ***/
import {l_storage} from '../utils/storage' //lostorage存储封装
export default {
    install: (Vm) => {
        Vm.mixin({
            data() {
                return {
                    // configHeaders:{
                    //     'Authorization':l_storage.get('information').perId
                    // },
                    //检测按钮操作权限
                    permissionBtn: id => l_storage.get('information').perId.includes(id)
                }
            },
            methods:{


            }
        });
    }
}
