/**
 * 物业平台路由
 * @date: 2020.09.01
 */

export default [
  {
    path: '/welcome',
    name: 'welcome',
    meta: { title: '欢迎', requireAuth: true },
    component: () => import('@/components/welcome.vue'),
  },
  {
    path: '/home',
    name: 'home',
    icon: 'el-icon-s-home',
    meta: { title: '主页', requireAuth: true },
    component: () => import('@/views/Home/J_welcome.vue'),
  },
  {
    path: '/elevator_information',
    name: 'elevator_information',
    component: function () {
      //电梯管理-电梯资料
      return import('../../views/elevatorManage/Elevator/elevator_information.vue')
    },
    meta: {
      permitName: 'elevator_information',
    },
  },
  {
    path: '/single_ladder_management',
    name: 'single_ladder_management',
    component: function () {
      //电梯管理-单梯管理
      return import('../../views/elevatorManage/singleLadderManagement/index.vue')
    },
    meta: {
      permitName: 'single_ladder_management',
    },
  },
  {
    path: '/notice_announcement',
    name: 'notice_announcement',
    component: function () {
      //电梯管理-通知公告
      return import('../../views/elevatorManage/noticeAnnouncement/index.vue')
    },
    meta: {
      permitName: 'notice_announcement',
    },
  },
  {
    path: '/notice_announcement',
    name: 'notice_announcement',
    component: function () {
      //电梯管理-通知公告
      return import('../../views/elevatorManage/noticeAnnouncement/index.vue')
    },
    meta: {
      permitName: 'notice_announcement',
    },
  },
  {
    path: '/task_plan',
    name: 'task_plan',
    component: function () {
      //电梯管理-维保计划
      return import('../../views/elevatorManage/taskPlan/index.vue')
    },
    meta: {
      permitName: 'task_plan',
    },
  },
  {
    path: '/work_order',
    name: 'work_order',
    component: function () {
      //电梯管理-保养工单
      return import('../../views/elevatorManage/workOrder/index.vue')
    },
    meta: {
      permitName: 'work_order',
    },
  },
  {
    path: '/fault_warning',
    name: 'fault_warning',
    component: function () {
      //告警记录-故障警告
      return import('../../views/alarmRecord/faultAlarm/index.vue')
    },
    meta: {
      permitName: 'fault_warning',
    },
  },
  {
    path: '/event_firmware',
    name: 'event_firmware',
    component: function () {
      //告警记录-事件警告
      return import('../../views/alarmRecord/eventAlarm/index.vue')
    },
    meta: {
      permitName: 'event_firmware',
    },
  },
  {
    path: '/tiring_disposal',
    name: 'tiring_disposal',
    component: function () {
      //告警记录-困人告警
      return import('../../views/EmergencyDisposal/TiringDisposal/tiring_disposal.vue')
    },
    meta: {
      permitName: 'tiring_disposal',
    },
  },
  {
    path: '/repair_list',
    name: 'repair_list',
    component: function () {
      //报修报事-电梯召修
      return import('../../views/reportRepair/repairLift')
    },
    meta: {
      permitName: 'repair_list',
    },
  },
  {
    path: '/tiring_rescue',
    name: 'tiring_rescue',
    component: function () {
      //应急处置-困人救援
      return import('../../views/EmergencyDisposal/TiringRescue')
    },
    meta: {
      permitName: 'tiring_disposal',
    },
  },
  {
    path: '/key_call',
    name: 'key_call',
    component: function () {
      //应急处置-一键呼救
      return import('../../views/EmergencyDisposal/KeyCall/key_call.vue')
    },
    meta: {
      permitName: 'key_call',
    },
  },
  {
    path: '/role_management',
    name: 'role_management',
    component: function () {
      //管理中心-角色管理
      return import('../../views/ManagementCenter/Role/role_management.vue')
    },
    meta: {
      permitName: 'role_management',
    },
  },
  {
    path: '/user_management',
    name: 'user_management',
    component: function () {
      //管理中心-用户管理
      return import('../../views/ManagementCenter/User/user_management.vue')
    },
    meta: {
      permitName: 'user_management',
    },
  },
  {
    path: '/elevator_monitoring',
    name: 'elevator_monitoring',
    component: function () {
      //电梯监控-电梯监控
      return import('../../views/elevator/Monitor/monitor.vue')
    },
    meta: {
      permitName: 'elevator_monitoring',
    },
  },
  {
    path: '/real_time',
    name: 'real_time',
    component: function () {
      //电梯实时状态-电梯实时状态
      return import('../../views/elevator/RealStatus/real_status.vue')
    },
    meta: {
      permitName: 'real_time',
    },
  },
  {
    path: '/run_reports',
    name: 'run_reports',
    component: function () {
      //运行报告-运行报告
      return import('../../views/statistical_analysis/run_reports.vue')
    },
    meta: {
      permitName: 'run_reports',
    },
  },
  {
    path: '/essential_information',
    name: 'essential_information',
    component: function () {
      //个人中心-基础信息
      return import(
        '../../views/PersonalCenter/BasicInformation/essential_information.vue'
      )
    },
    meta: {
      permitName: 'essential_information',
    },
  },
  {
    path: '/policy_announcement',
    name: 'policy_announcement',
    component: function () {
      //政策公告-政策公告
      return import('../../views/policyAnnouncement/policyAnnouncement.vue')
    },
    meta: {
      permitName: 'policy_announcement',
    },
  },
  {
    path: '/contract_management',
    name: 'contract_management',
    component: function () {
      //合同管理-合同管理
      return import('../../views/contractManagement')
    },
    meta: {
      permitName: 'contract_management',
    },
  },
  {
    path: '/public_feedback',
    name: 'public_feedback',
    component: function () {
      //公众反馈-公众反馈
      return import('../../views/publicFeedBack')
    },
    meta: {
      permitName: 'public_feedback',
    },
  },
]
